import React from "react";
import { Test as TestComponent } from "../components/test/Test";

const Test = () => {
  return (
    <div>
      <TestComponent />
    </div>
  );
};

export default Test;
