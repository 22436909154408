import { Box, Button, ChakraProvider } from "@chakra-ui/react";
import React from "react";
import theme from "../../theme"; // Adjust the path as necessary
import Sidebar from "../navigation/Sidebar";

import { Auth0Provider } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useUser } from "../../hooks/use-user";
import useApiRequest from "../../lib/hooks/useRequest";
import { ContextProvider } from "./context";
import ErrorPage from "./ErrorPage";

const AuthProvider = ({ children }: any) => {
  const redirectUri = window.location.origin;

  if (
    !(
      process.env.REACT_APP_AUTH0_DOMAIN &&
      process.env.REACT_APP_AUTH0_ID &&
      process.env.REACT_APP_AUTH0_AUDIENCE &&
      redirectUri
    )
  ) {
    return null;
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: "openid profile email offline_access",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        ui_locales: "de",
      }}
    >
      {children}
    </Auth0Provider>
  );
};

const RootWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const excludeSidebar = ["/register", "/organization/neu", "/reset", "/verification/mail", "/verification/2fa"];

  const location = useLocation();

  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Box display="flex" gap="1em" width="100%" height="100%" minHeight="100vh">
          <ContextProvider>
            {!excludeSidebar.includes(location.pathname) && <Sidebar />}
            <Wrapper margin={!excludeSidebar.includes(location.pathname)}>{children}</Wrapper>
          </ContextProvider>
        </Box>
      </ChakraProvider>
    </AuthProvider>
  );
};

const Wrapper = ({ children, margin }: { children: React.ReactNode; margin: boolean }) => {
  const { user } = useUser();
  try {
    return (
      <>
        <Box
          className={margin ? "py-3 px-5 min-h-full overflow-x-hidden" : ""}
          marginLeft={margin ? "82px" : ""}
          paddingBottom={user?.showBackToSuperadmin || user?.showSuperadminPanel ? "4rem" : ""}
          background="#F9FAFC"
          minHeight="100vh"
          width="100%"
        >
          {children}
        </Box>
        {(user?.showBackToSuperadmin || user?.showSuperadminPanel) && <SuperAdminWrapper />}
      </>
    );
  } catch (error) {
    return <ErrorPage />;
  }
};

const SuperAdminWrapper = () => {
  const { user } = useUser();
  const { apiRequest } = useApiRequest();

  const logout = async () => {
    await apiRequest("organizations/logout", "POST");
    window.location.href = "/super-admin";
  };

  return (
    <div className="fixed bottom-0 m-auto flex justify-center w-1/2 left-0 right-0">
      {user?.showBackToSuperadmin ? (
        <div className="border-2 border-b-0 border-primary w-full p-4 bg-white">
          <div className="flex justify-between">
            <div>
              <p>
                Eingeloggt als {user?.firstName} {user?.lastName}
              </p>
              <p className="text-sm text-gray-500 italic">{user?.organizationName}</p>
            </div>
            <div>
              <Button
                onClick={() => logout()}
                colorScheme="red"
                className="flex gap-2 p-2 text-white"
                variant="outline"
              >
                Abmelden
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RootWrapper;
