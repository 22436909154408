import { OrganizationResponseDto } from "@/lib/interfaces/organizations";
import { SmallAddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Save } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import CompanySettings from "./CompanySettings";
import EmailSignature from "./EmailSignature";
import InternalMail from "./InternalMail";
import PrivacyPolicy from "./PrivacyPolicy";
import "./settings-styles.css";
import ThirdPartyApps from "./ThirdPartyApps";
import { UserDataTable } from "./UserDataTable";

const Settings = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const { apiRequest } = useApiRequest();

  const [organization, setOrganization] = useState<OrganizationResponseDto>();

  const getOrganizationSettings = useCallback(async () => {
    const response = await apiRequest<OrganizationResponseDto>("organizations", "GET");

    if (response?.data) {
      setOrganization(response.data);
    } else {
      // Should return some error and hide spinner / preloader
      // alert("An error!!");
      setOrganization(undefined);
      // console.log("NO RESPONSE");
    }
  }, [apiRequest]);

  useEffect(() => {
    // get organization details from API
    getOrganizationSettings();
  }, [apiRequest, getOrganizationSettings]);

  return (
    <Flex direction="column" padding="1rem 1.5rem" className="h-full">
      <Heading size="lg" marginBottom="1.5rem">
        Einstellungen
      </Heading>
      {organization ? (
        <Tabs
          position="relative"
          height="100%"
          flex="1"
          display="flex"
          flexDirection="column"
          onChange={(e) => setCurrentTab(e)}
        >
          <TabList overflowX="auto">
            <Tab sx={{ color: "#7C8080" }} className="text-nowrap h-full ">
              Personalisierung/Unternehmen
            </Tab>
            <Tab sx={{ color: "#7C8080" }} className="text-nowrap h-full">
              Email-Signatur
            </Tab>
            <Tab sx={{ color: "#7C8080" }} className="text-nowrap h-full">
              Schnittstellen
            </Tab>
            <Tab sx={{ color: "#7C8080" }} className="text-nowrap h-full">
              Rollen und Rechte
            </Tab>
            <Tab sx={{ color: "#7C8080" }} className="text-nowrap h-full">
              Datenschutzerklärung
            </Tab>
            <Tab sx={{ color: "#7C8080" }} className="text-nowrap h-full">
              Agentur
            </Tab>
            <Tab sx={{ color: "#7C8080" }} className="text-nowrap h-full">
              Abo
            </Tab>
            <Tab sx={{ color: "#7C8080" }} className="text-nowrap h-full">
              Interne Benachrichtigung
            </Tab>
          </TabList>
          <TabPanels display="flex" flexDirection="column" flex="1">
            <TabPanel height="100%" padding="1.5rem 0">
              {currentTab === 0 && (
                <Flex className="px-8 py-4 rounded border border-gray-300 h-full flex-col gap-12 bg-white">
                  <CompanySettings organization={organization} />
                </Flex>
              )}
            </TabPanel>
            <TabPanel height="100%" padding="1.5rem 0">
              {currentTab === 1 && <EmailSignature />}
            </TabPanel>
            <TabPanel height="100%" padding="1.5rem 0">
              {currentTab === 2 && <ThirdPartyApps />}
            </TabPanel>
            <TabPanel height="100%" padding="1.5rem 0" position="relative">
              <Button
                position="absolute"
                colorScheme="primary"
                color="white"
                className="flex gap-2 p-2 text-white absolute right-3 -top-24"
                variant="solid"
                onClick={() => {
                  window.location.href = `/nutzer/neu`;
                }}
              >
                <Box
                  className="text-primary"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                  bg="white"
                  boxSize="1rem"
                >
                  <SmallAddIcon />
                </Box>
                <span>Benutzer hinzufügen</span>
              </Button>
              <UserDataTable />
            </TabPanel>
            <TabPanel height="100%" padding="1.5rem 0">
              <PrivacyPolicy organization={organization} />
            </TabPanel>
            <TabPanel height="100%" padding="1.5rem 0">
              <Flex className="px-8 py-4 rounded border border-gray-300 h-full flex-col gap-12 bg-white">
                <AgencyForm organization={organization} />
              </Flex>
            </TabPanel>
            <TabPanel height="100%" padding="1.5rem 0">
              <Flex className="px-8 py-4 rounded border border-gray-300 h-full flex-col gap-6 bg-white">
                <Text className="text-gray-400 text-sm">
                  Klicken Sie auf den Button und melde Sie sich an, um die Details Ihres Abos und die dazugehörigen
                  Rechnungen einzusehen
                </Text>
                <Link to={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL!} target="_blank">
                  <Button colorScheme="primary" className="flex gap-2 p-2 text-white w-fit" variant="solid">
                    Abonnement verwalten
                  </Button>
                </Link>
              </Flex>
            </TabPanel>
            <TabPanel height="100%" padding="1.5rem 0">
              <Flex className="px-8 py-4 rounded border border-gray-300 h-full flex-col gap-12 bg-white">
                <InternalMail organization={organization} />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <Flex className="justify-center w-full h-52 items-center">
          <Spinner size="xl" color="primary.500" />
        </Flex>
      )}
    </Flex>
  );
};

const AgencyForm = ({ organization }: { organization: OrganizationResponseDto }) => {
  const { apiRequest } = useApiRequest();

  const formSchema = z.object({
    agencyEmail: z.string().email("Bitte geben Sie eine E-Mail Adresse ein"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      agencyEmail: organization?.agencyEmail ?? "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    await apiRequest(`organizations/`, "PATCH", {
      body: {
        agencyEmail: values.agencyEmail,
      },
      toast: { toastText: "Daten erfolgreich aktualisiert" },
    });
  }

  if (!organization) {
    return <Spinner />;
  } else {
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col gap-8 h-full bg-white">
        <Flex className="h-full flex-col">
          <Text className="text-gray-400 text-sm">
            Wenn Sie mit einer Agentur für die Personalgewinnung zusammenarbeiten, die die Stellenanzeigen verwaltet,
            geben Sie hier die entsprechende E-Mail-Adresse ein. Die Agentur wird benachrichtigt, sobald Sie eine
            Stellenanzeige in kandidat.io aktivieren oder deaktivieren.
          </Text>
          <Box className="flex gap-2">
            <FormField
              control={form.control}
              name="agencyEmail"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full grow">
                  <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">
                    Agentur Email <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
          </Box>
          <div className="h-full flex">
            <Text className="w-fit m-auto">
              Sie arbeiten noch mit keiner Agentur zusammen?
              <br />
              Werfen Sie doch einen Blick auf{" "}
              <Link to="https://freshtalents.de/" className="text-blue-500 hover:underline" target="_blank">
                www.freshtalents.de
              </Link>
            </Text>
          </div>
        </Flex>
        <Flex className="gap-4 justify-end mt-auto">
          <Button
            colorScheme="primary"
            color="white"
            className="flex gap-2 p-2 text-white ml-auto mt-4"
            variant="solid"
            type="submit"
          >
            <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
              <Save />
            </Box>
            <span>Speichern</span>
          </Button>
        </Flex>
      </form>
    </Form>
  );
};

export { Settings };
