// theme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Select: {
      baseStyle: {
        field: {
          paddingBottom: "8px", // Adjust the padding value as needed
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          bg: "#E35700",
          _checked: {
            bg: "#91BA07", // Color when switch is on
          },
          _unchecked: {
            bg: "#E35700", // Color when switch is off
          },
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          fontWeight: "semibold",
          _selected: {
            color: "#0099ce",
          },
        },
      },
    },
  },
  colors: {
    primary: {
      50: "#e3f2fd",
      100: "#bbdefb",
      200: "#90caf9",
      300: "#64b5f6",
      400: "#42a5f5",
      500: "#0099ce", // Primary color
      600: "#0099ce",
      700: "#1976d2",
      800: "#1565c0",
      900: "#0d47a1",
    },
    success: {
      50: "#f0fdf4",
      100: "#dcfce7",
      200: "#bbf7d0",
      300: "#86efac",
      400: "#4ade80",
      500: "#20c997", // Success color
      600: "#12b886",
      700: "#0ca678",
      800: "#099268",
      900: "#087f5b",
    },
    error: "#E35700",
  },
});

export default theme;
