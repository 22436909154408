import { Button as ChakraButton, Flex, Spinner, Text } from "@chakra-ui/react";
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, InfoIcon, MoreHorizontal, Trash2Icon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Button } from "../../ui/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/components/ui/dropdown-menu";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/components/ui/table";
// import GlobalFilter from "./GlobalFilter";
import { ApplicantListEntitiesResponseDto, ApplicantListResponseDto } from "@/lib/interfaces/applicant";
import { Paginated } from "@/lib/interfaces/utils";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import { Link, useNavigate } from "react-router-dom";
import useApiRequest from "../../lib/hooks/useRequest";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/components/ui/dialog";
import GutIcon from "../../ui/icons/GutIcon";
import NeutralIcon from "../../ui/icons/NeutralIcon";
import SchlechtIcon from "../../ui/icons/SchlechtIcon";
import AppStatus from "./AppStatus";
import { useUser } from "../../hooks/use-user";

export enum Evaluation {
  Gut = "GOOD",
  Schlecht = "BAD",
  Neutral = "NEUTRAL",
}

const evaluationIcons = {
  [Evaluation.Gut]: GutIcon,
  [Evaluation.Schlecht]: SchlechtIcon,
  [Evaluation.Neutral]: NeutralIcon,
};

export enum Status {
  Offen = "Offen",
  NichtErreicht = "Nicht Erreicht",
  Abgesagt = "Abgesagt",
  Eingestellt = "Eingestellt",
  Eingeladen = "Eingeladen",
}

const columnNames: { [key: string]: string } = {
  name: "Name",
  placeName: "Wohnort",
  jobOfferTitle: "Beworben als",
  companyLocationTitle: "Standort",
  // experience: "Berufserfahrung",
  applicationDate: "Datum der Bewerbung",
  valuation: "Bewertung",
  status: "Status",
  todo: "ToDos",
  source: "Quelle",
};

const globalFilterFunction: FilterFn<ApplicantListResponseDto> = (row, columnId, filterValue) => {
  return Object.values(row.original).some((value) =>
    String(value).toLowerCase().includes(String(filterValue).toLowerCase())
  );
};

function DataTableParent() {
  const [listEntities, setListEntities] = useState<ApplicantListEntitiesResponseDto>();
  const { apiRequest } = useApiRequest();

  const getListEntities = useCallback(async () => {
    const response = await apiRequest<ApplicantListEntitiesResponseDto>("applicants/list-entities", "GET");

    if (response.data) {
      setListEntities(response.data);
    }
  }, [apiRequest]);

  useEffect(() => {
    getListEntities();
  }, [getListEntities]);

  return <>{listEntities && <DataTable listEntities={listEntities} />}</>;
}

function DataTable({ listEntities }: { listEntities: ApplicantListEntitiesResponseDto }) {
  // function DataTable({ data, status }: { data: ApplicantListResponseDto[]; status: ApplicantListEntitiesResponseDto }) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const [isAnonymizeDialogOpen, setIsAnonymizeDialogOpen] = useState<{
    isOpen: boolean;
    applicant: ApplicantListResponseDto;
  }>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    applicant: ApplicantListResponseDto;
  }>();

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();

  const handleDeleteClick = async (id: string, action: "anonymize" | "delete") => {
    await apiRequest(`applicants/${id}/${action === "anonymize" ? "anonymize" : ""}`, "DELETE");
    if (action === "delete") {
      setIsDeleteDialogOpen(undefined);
    } else {
      setIsAnonymizeDialogOpen(undefined);
    }
    getApplicants();
  };

  const columns: ColumnDef<ApplicantListResponseDto>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.name}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.name}</Text>
        );
      },
      cell: ({ row }) => {
        const applicant = row.original;
        return <Link to={`/bewerber/${applicant._id}`}>{row.getValue("name")}</Link>;
      },
    },
    {
      accessorKey: "placeName",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.placeName}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.placeName}</Text>
        );
      },
    },
    {
      accessorKey: "jobOfferTitle",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.jobOfferTitle}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.jobOfferTitle}</Text>
        );
      },
      cell: ({ row }) => {
        return row.getValue("jobOfferTitle") || " - ";
      },
    },
    {
      accessorKey: "companyLocationTitle",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.companyLocationTitle}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.companyLocationTitle}</Text>
        );
      },
      cell: ({ row }) => {
        return row.getValue("companyLocationTitle") || " - ";
      },
    },
    // {
    //   accessorKey: "experience",
    //   header: ({ column }) => {
    //     return (
    //       // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
    //       //   {columnNames.experience}
    //       //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
    //       // </Text>
    //       <Text>{columnNames.experience}</Text>
    //     );
    //   },
    // },
    {
      accessorKey: "applicationDate",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.applicationDate}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.applicationDate}</Text>
        );
      },
      cell: ({ row }) => {
        return new Date(row.getValue("applicationDate")).toLocaleDateString();
      },
    },

    {
      accessorKey: "valuation",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.valuation}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.valuation}</Text>
        );
      },
      cell: ({ row }) => {
        const evaluationValue = row.getValue("valuation") as Evaluation;
        const IconComponent = evaluationIcons[evaluationValue];

        return <div className="flex">{IconComponent ? <IconComponent selected={true} /> : null}</div>;
        // return <div className="flex">{row.getValue("valuation")}</div>;
      },
    },
    {
      accessorKey: "belongsToStatus",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.status}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.status}</Text>
        );
      },
      cell: ({ row }) => {
        const statusText = listEntities.status.find((status) => {
          return status.id === row.getValue("belongsToStatus");
        });

        return (
          <div className={`capitalize gap-2 flex items-center`}>
            <AppStatus status={statusText ? statusText.value : ""} color={statusText ? statusText.color : ""} />
          </div>
        );
      },
    },
    {
      accessorKey: "belongsToSource",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.status}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.source}</Text>
        );
      },
      cell: ({ row }) => {
        const sourceText = listEntities.sources.find((source) => {
          return source.id === row.getValue("belongsToSource");
        });

        return <div className={`capitalize gap-2 flex items-center`}>{sourceText ? sourceText.value : ""}</div>;
      },
    },
    {
      accessorKey: "todo",
      header: ({ column }) => {
        return <Text>{columnNames.todo}</Text>;
      },
      cell: ({ row }) => {
        return <div className="flex">{row.getValue("todo") ?? ""}</div>;
      },
    },
    {
      id: "actions",
      header: "Details",
      enableHiding: false,
      cell: ({ row, cell }) => {
        const applicant = row.original;
        return (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem className="flex gap-2 cursor-pointer">
                <Link to={`/bewerber/${applicant._id}`} className="flex gap-2">
                  <InfoIcon className="h-4 w-4 text-primary self-center" />
                  Details{" "}
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="flex gap-2 cursor-pointer"
                disabled={user?.permissionCompanyLocations !== "FULL" && !user?.permissionOrganizationAdmin}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsAnonymizeDialogOpen({
                    isOpen: true,
                    applicant: applicant,
                  });
                }}
              >
                <EyeOpenIcon className="h-4 w-4 text-primary" />
                Anonymisieren
              </DropdownMenuItem>
              <DropdownMenuItem
                disabled={user?.permissionCompanyLocations !== "FULL" && !user?.permissionOrganizationAdmin}
                className="flex gap-2 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteDialogOpen({
                    isOpen: true,
                    applicant: applicant,
                  });
                }}
              >
                <Trash2Icon className="h-4 w-4 text-error" />
                Löschen
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();

  const [data, setData] = useState<ApplicantListResponseDto[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // fetch the data
  // as soon as it is fetched set the data to the table

  const table = useReactTable({
    data,
    columns,
    // pageCount: Math.ceil(data.length / 10),
    pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    globalFilterFn: globalFilterFunction,
    onGlobalFilterChange: setGlobalFilter,
    initialState: { pagination: { pageSize: 5 }, globalFilter },
    state: {
      sorting,
      pagination,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
  });

  const getApplicants = useCallback(async () => {
    setIsLoading(true);
    const response = await apiRequest<Paginated<ApplicantListResponseDto>>(
      `applicants?limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}`,
      "GET"
    );

    if (response.data) {
      setData(response.data.docs);
      setPageCount(Math.ceil(response.data.count / pagination.pageSize));
      setItemCount(response.data.count);
    }
    setIsLoading(false);
  }, [apiRequest, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    getApplicants();
  }, [getApplicants]);

  return (
    <div className="w-full">
      <Flex className="items-center py-4 gap-4">
        <Dialog
          open={isAnonymizeDialogOpen?.isOpen || isDeleteDialogOpen?.isOpen}
          onOpenChange={
            isAnonymizeDialogOpen?.isOpen
              ? (value) => {
                  setIsAnonymizeDialogOpen(
                    value === false
                      ? undefined
                      : {
                          isOpen: true,
                          applicant: isAnonymizeDialogOpen.applicant,
                        }
                  );
                }
              : (value) =>
                  setIsDeleteDialogOpen(
                    value === false
                      ? undefined
                      : isDeleteDialogOpen?.applicant
                      ? {
                          isOpen: true,
                          applicant: isDeleteDialogOpen.applicant,
                        }
                      : undefined
                  )
          }
        >
          {(isAnonymizeDialogOpen || isDeleteDialogOpen) && (
            <>
              {isAnonymizeDialogOpen?.applicant && (
                <X
                  applicant={isAnonymizeDialogOpen.applicant}
                  isAnonymizeDialogOpen={isAnonymizeDialogOpen.isOpen}
                  handleDeleteClick={handleDeleteClick}
                />
              )}
              {isDeleteDialogOpen?.applicant && (
                <X
                  applicant={isDeleteDialogOpen.applicant}
                  isAnonymizeDialogOpen={isAnonymizeDialogOpen?.isOpen ?? false}
                  handleDeleteClick={handleDeleteClick}
                />
              )}
            </>
          )}
        </Dialog>
        {/* <Flex gap="1rem">
          <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        </Flex> */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Spalten <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(!!value)}
                  >
                    {columnNames[column.id.toString()]}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>
      <div className="rounded-md border overflow-hidden">
        {isLoading ? (
          <Flex className="justify-center w-full h-36 items-center bg-white">
            <Spinner size="xl" color="primary.500" />
          </Flex>
        ) : (
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className={`hover:bg-white`}>
                  {headerGroup.headers.map((header) => {
                    // console.log(header.column.columnDef.header);

                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row, i) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={`hover:bg-slate-100 cursor-pointer`}
                    onClick={() => {
                      navigate(`/bewerber/${row.original._id}`);
                    }}
                  >
                    {row.getVisibleCells().map((cell, j) => (
                      <TableCell key={cell.id} className={`${j === 0 ? " font-bold" : ""}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    Keine Resultate.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
      <div className="flex items-center justify-between py-4">
        <Text className="text-sm text-gray-500">
          * Aus datenschutzrechtlichen Gründen werden Bewerberdaten 6 Monate nach Eintragung anonymisiert.
        </Text>
        {/* <div className="flex-1 text-sm text-muted-foreground">
                  {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s)
                  selected.
                </div> */}
        <div className="flex flex-col gap-2">
          <div className="text-sm text-end">{itemCount} Ergebnisse gesamt</div>
          <Flex gap="6">
            <div className="flex space-x-2 items-center">
              {/* <Text size="sm">
            Seite {table.getState().pagination.pageIndex + 1} von {table.getPageCount()}
          </Text> */}
              <Text fontSize="medium">
                Seite {pagination.pageIndex + 1} von {pageCount}
              </Text>
              <Button
                variant="outline"
                size="sm"
                className="text-base"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                zurück
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                className="text-base"
              >
                weiter
              </Button>
            </div>
            <Flex alignItems="center">
              <Text fontSize="medium" className="text-base">
                Zeigen
              </Text>
              <select
                className="py-1 pr-7 pl-3 ml-2 rounded-sm border-gray-200 text-sm"
                value={pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 15, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </Flex>
          </Flex>
        </div>
      </div>
    </div>
  );
}

const X = ({
  applicant,
  isAnonymizeDialogOpen,
  handleDeleteClick,
}: {
  applicant: ApplicantListResponseDto;
  isAnonymizeDialogOpen: boolean;
  handleDeleteClick: (id: string, action: "anonymize" | "delete") => void;
}) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          Bewerber {applicant.name} {isAnonymizeDialogOpen ? "anonymisieren" : "löschen"}
        </DialogTitle>
        <DialogDescription>
          {isAnonymizeDialogOpen
            ? "Sind Sie sich sicher, dass Sie den Bewerber anonymisieren wollen? Diese Aktion kann nicht rückgängig gemacht werden. Er wird immer noch in der Statistik angezeigt"
            : "Sind Sie sich sicher, dass Sie den Bewerber löschen wollen? Diese Aktion kann nicht rückgängig gemacht werden. Er wird auch aus der Statistik gelöscht"}
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose asChild>
          <ChakraButton
            colorScheme="primary"
            className="flex gap-2 p-2 text-white"
            justifyContent="flex-start"
            variant="outline"
          >
            Schliessen
          </ChakraButton>
        </DialogClose>
        <DialogClose asChild>
          <ChakraButton
            borderColor="error"
            textColor="error"
            className="flex gap-2 p-2"
            justifyContent="flex-start"
            variant="outline"
            onClick={() => handleDeleteClick(applicant._id, isAnonymizeDialogOpen ? "anonymize" : "delete")}
          >
            {isAnonymizeDialogOpen ? "Anonymisieren" : "Löschen"}
          </ChakraButton>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};

export { DataTableParent as DataTable };
