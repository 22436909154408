import { Box, Button, Heading } from "@chakra-ui/react";
import { ReloadIcon } from "@radix-ui/react-icons";
import React, { Component, ReactNode, ErrorInfo } from "react";
// @slava - added an error component - yet without styling and looking bad
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  handleReload = (): void => {
    window.location.reload();
  };

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div>
          <div className="w-1/2 m-auto h-full flex flex-col justify-center gap-8">
            <Heading className="mt-60">
              Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den
              Support.
            </Heading>
            <Button
              onClick={this.handleReload}
              colorScheme="primary"
              className="flex gap-2 p-2 text-white"
              variant="solid"
            >
              <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                <ReloadIcon />
              </Box>
              <span>Seite neu laden</span>
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
