import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      <div className="group flex text-white items-center h-16 p-2">
        <img
          src="../logo.svg"
          alt="logo"
          height="42px"
          width="42px"
          style={{ maxHeight: "50px", maxWidth: "50px", marginRight: "10px" }}
        />
        <img
          src="../logo-full.svg"
          alt="logo"
          height="42px"
          width="150px"
          style={{ maxHeight: "50px", maxWidth: "150px", marginRight: "10px" }}
          className="hidden opacity-0 group-hover:flex group-hover:opacity-100 text-4xl"
        />
      </div>
    </Link>
  );
};

export default Logo;
