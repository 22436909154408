import { MailTemplateResponseDto, UpdateMailTemplateRequestDto } from "@/lib/interfaces/mail";
import { Box, Button, Flex, Heading, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Save } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import useApiRequest from "../../lib/hooks/useRequest";
import { convertHtmlToEditorState } from "../../lib/utils/convertHtml";
import { Input } from "../../ui/components/ui/input";
import Placeholder from "./Placeholder";
import { useUser } from "../../hooks/use-user";

const EmailTabPanel = ({ mailLabel, placeholders }: { mailLabel: string | undefined; placeholders: string[] }) => {
  const [mailTemplates, setMailTemplates] = useState<MailTemplateResponseDto[] | undefined>();

  const { apiRequest } = useApiRequest();

  const getEmailTemplate = useCallback(async () => {
    const response = await apiRequest<any>(`mail-templates/label/${mailLabel}`, "GET");

    if (response?.data) {
      setMailTemplates(response.data);
    } else {
      setMailTemplates(undefined);
    }
  }, [apiRequest, mailLabel]);

  useEffect(() => {
    getEmailTemplate();
  }, [apiRequest, getEmailTemplate]);

  return (
    <Flex className="p-8 rounded border border-gray-300 gap-8 flex-col h-full bg-white">
      {mailTemplates ? (
        <>
          <Tabs position="relative" height="100%" flex="1" display="flex" flexDirection="column" isLazy>
            <TabList>
              {mailTemplates.map((item, index) => {
                return (
                  <Tab sx={{ color: "#7C8080" }} key={index}>
                    {item.title}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels display="flex" flexDirection="column" flex="1">
              {mailTemplates.map((item, index) => {
                return (
                  <TabPanel key={index}>
                    <EmailField mailTemplate={item} placeholders={placeholders} />{" "}
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <Flex className="justify-center w-full h-52 items-center">
          <Spinner size="xl" color="primary.500" />
        </Flex>
      )}
    </Flex>
  );
};

const EmailField = ({
  mailTemplate,
  placeholders,
}: {
  mailTemplate: MailTemplateResponseDto;
  placeholders: string[];
}) => {
  const { apiRequest } = useApiRequest();
  const { user } = useUser();

  const updateTemplate = async () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    // Convert raw content to plain text
    const htmlContent = draftToHtml(rawContentState);

    const req: UpdateMailTemplateRequestDto = {
      template: htmlContent,
      subject: subject,
    };

    await apiRequest(`mail-templates/${mailTemplate?._id}`, "PATCH", {
      body: req,
      toast: { toastText: "Email-Vorlagen wurde erfolgreich aktualisiert" },
    });
  };

  const [subject, setSubject] = useState<string>(mailTemplate?.subject ?? "");

  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(mailTemplate?.template ?? ""));

  const onEditorStateChange = (newState: EditorState) => {
    setEditorState(newState);
  };

  if (!user) return null;

  const isDisabled =
    user?.permissionMailTemplates !== "WRITE" &&
    user?.permissionMailTemplates !== "FULL" &&
    !user?.permissionOrganizationAdmin;

  return (
    <>
      <Flex className="gap-8 mb-6">
        <Box className="w-2/5">
          <Heading size="sm" className="font-bold mb-4">
            {mailTemplate?.title}
          </Heading>
          <Text>{mailTemplate?.description}</Text>
        </Box>
        <Box className="w-3/5">
          <div className="flex flex-col gap-3 w-full mb-5">
            <label className="w-full font-bold mt-4 text-md">Betreff</label>
            <div className="w-full">
              <Input
                type="text"
                placeholder="Betreff"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                disabled={isDisabled}
              />
            </div>
          </div>
          <Placeholder
            placeholders={placeholders}
            onPlaceholderClicked={(placeholder) => {
              if (isDisabled) return;
              const contentState = editorState.getCurrentContent();
              const selectionState = editorState.getSelection();

              const newContentState = Modifier.insertText(contentState, selectionState, `[${placeholder}]`);

              const newEditorState = EditorState.push(editorState, newContentState, "insert-characters");
              setEditorState(newEditorState);
            }}
          />
          <Editor
            editorState={editorState}
            wrapperClassName="editorWrapper"
            editorClassName="editorContent"
            onEditorStateChange={onEditorStateChange}
            readOnly={isDisabled}
          />
        </Box>
      </Flex>
      <Flex className="gap-4 justify-end mt-auto">
        <Button
          colorScheme="primary"
          color="white"
          className="flex gap-2 p-2 text-white"
          variant="solid"
          onClick={updateTemplate}
          isDisabled={isDisabled}
        >
          <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
            <Save />
          </Box>
          <span>Speichern</span>
        </Button>
      </Flex>
    </>
  );
};

export default EmailTabPanel;
