import { Box, Button, Flex, Heading, Input, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { KeyRound, Save } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import useApiRequest from "../../lib/hooks/useRequest";

const UserSettings = () => {
  const [user, setUser] = useState<{
    mail: string;
  }>();

  const { apiRequest } = useApiRequest();

  const getUser = useCallback(async () => {
    const response = await apiRequest<{
      mail: string;
    }>("users", "GET");

    if (response?.data) {
      setUser(response.data);
    } else {
      setUser(undefined);
    }
  }, [apiRequest]);

  const resetPassword = async () => {
    await apiRequest("users/reset-password", "POST", {
      toast: {
        toastText: "Eine E-Mail zum Passwort zurücksetzen wurde soeben versendet. Prüfen Sie auch das Spam Postfach!",
      },
    });
  };

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <Flex direction="column" padding="1rem 1.5rem" className="h-full">
      <Heading size="lg" marginBottom="1.5rem">
        Benutzereinstellungen
      </Heading>
      {user ? (
        <Tabs position="relative" height="100%" flex="1" display="flex" flexDirection="column">
          <TabList overflowX="auto">
            <Tab sx={{ color: "#7C8080" }} className="text-nowrap h-full">
              Zugangsdaten
            </Tab>
          </TabList>
          <TabPanels display="flex" flexDirection="column" flex="1">
            <TabPanel height="100%" padding="1.5rem 0">
              <Flex className="p-8 rounded border border-gray-300 h-full flex-col gap-12 bg-white">
                <Flex className="gap-24">
                  <Box className="max-w-[800px] w-full">
                    <Flex className="gap-4 mb-5 items-center">
                      <Box className="max-w-[186px] w-full font-bold">Email-Adresse</Box>
                      <Input placeholder="Email-Adresse" disabled value={user.mail} />
                    </Flex>
                    <Flex className="gap-4 mb-5 items-center">
                      <Box className="max-w-[186px] w-full font-bold">Passwort</Box>
                      <Flex className="gap-6 w-full">
                        <Box>
                          <Button
                            colorScheme="primary"
                            className="flex gap-2 py-2 px-4"
                            variant="outline"
                            onClick={resetPassword}
                          >
                            <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                              <KeyRound />
                            </Box>
                            <div>Passwort ändern</div>
                          </Button>
                        </Box>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
                <Button
                  colorScheme="primary"
                  color="white"
                  className="flex gap-2 p-2 text-white w-fit self-end mt-auto"
                  variant="solid"
                >
                  <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                    <Save />
                  </Box>
                  <span>Speichern</span>
                </Button>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <Flex className="justify-center w-full h-52 items-center">
          <Spinner size="xl" color="primary.500" />
        </Flex>
      )}
    </Flex>
  );
};
export { UserSettings };
