import { Box, Button } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";
import { useSearchParams } from "react-router-dom";

const TwoFAVerification = () => {
  const { apiRequest } = useApiRequest();
  let [searchParams] = useSearchParams();
  const mail = searchParams.get("mail");

  const resendCode = async () => {
    await apiRequest("users/mfa-token/send", "POST", {
      toast: {
        toastText: "Code wurde erneut gesendet",
      },
    });
  };

  const formSchema = z.object({
    code: z.string().max(6, "Gib den Code ein").min(6, "Gib den Code ein"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const req = {
      token: data.code,
    };
    const res = await apiRequest("users/mfa-token/verify", "POST", {
      body: req,
      toast: {
        toastText: "Identität bestätigt",
      },
    });
    if (res.status < 300 && res.status >= 200) {
      window.location.href = "/";
    }
  };

  return (
    <div
      className="w-full h-full"
      style={{
        background: `url("/login-bg.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-20 w-5/12">
          <div className="text-center flex gap-6 items-center justify-center">
            <img src="/logo.svg" alt="" className="w-[75px]" />
            <h2 className="text-white font-semibold text-4xl">kandidat.io</h2>
          </div>
          <Form {...form}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                form.handleSubmit(onSubmit)();
              }}
              className="bg-white p-8 rounded-lg flex flex-col gap-4"
            >
              <h3 className="text-2xl font-semibold text-center">Zweifaktor überprüfung</h3>
              <h4 className="text-lg text-center">Eine Email mit dem code wurde an {mail ?? "Ihre Mail"} gesendet</h4>
              <div className="form-inputs">
                <div className="row">
                  <FormField
                    control={form.control}
                    name="code"
                    render={({ field }) => (
                      <FormItem className="gap-4 mb-4 w-full">
                        <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                          Code<span className="text-primary">*</span>
                        </FormLabel>
                        <Box className="w-full">
                          <FormControl>
                            <Input placeholder="Code" {...field} />
                          </FormControl>
                          <FormMessage />
                        </Box>
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex justify-between mt-4">
                  <div className="flex flex-col gap-2">
                    <p className="text-gray-600">Code nicht erhalten?</p>
                    <Button
                      colorScheme="primary"
                      color="white"
                      className="flex gap-2 p-2 text-white self-end"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        resendCode();
                      }}
                    >
                      Code erneut senden
                    </Button>
                  </div>
                  <Button
                    colorScheme="primary"
                    color="white"
                    className="flex gap-2 p-2 text-white self-end"
                    variant="solid"
                    type="submit"
                  >
                    <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                      <Save />
                    </Box>
                    <span>Bestätigen</span>
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export { TwoFAVerification };
