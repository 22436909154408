import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { DonutChart } from "@tremor/react";

import {
  ApplicantsResponseDto,
  ListEntitiesResponseDto,
  MappedBarDiagramResponseDto,
  MappedCircleDiagramResponseDto,
  MappedStatisticsDto,
  SelectableEntitiesResponseDto,
  StatisticsInitialResponseDto,
  StatisticsResponseDto,
} from "@/lib/interfaces/statistics";
import { BarChart, EventProps } from "@tremor/react";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiRequest from "../../lib/hooks/useRequest";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/components/ui/select";
import PeopleGroupIcon from "../../ui/icons/PeopleGroupIcon";
import { useUser } from "../../hooks/use-user";

const statColors = ["#f59c19", "#e94360", "#6660a6", "#31b198", "#4AA5F5", "purple", "lime", "teal", "aqua", "maroon"];

const Home = () => {
  const [statistics, setStatistics] = useState<MappedStatisticsDto>();
  const [listEntities, setListEntities] = useState<ListEntitiesResponseDto>();
  const [selectableEntities, setSelectableEntities] = useState<SelectableEntitiesResponseDto>();
  const [loading, setLoading] = useState(true);
  const [currentYear, setCurrentYear] = useState<string>();
  const [selectedCompanyLocation, setSelectedCompanyLocation] = useState<string>();
  const [applicants, setApplicants] = useState<ApplicantsResponseDto>();
  const [initJustLoaded, setInitJustLoaded] = useState(false);
  const { user } = useUser();

  const { apiRequest } = useApiRequest();

  const initRequest = useCallback(async () => {
    setLoading(true);
    const res = await apiRequest<StatisticsInitialResponseDto>("statistics/initial?limit=10", "GET");
    if (res.data) {
      setStatistics(
        mapStatisticsWithTitles(
          {
            bar: res.data.bar,
            circle: res.data.circle,
          },
          res.data.listEntities
        )
      );
      setListEntities(res.data.listEntities);
      setSelectableEntities(res.data.selectableEntities);
      setApplicants(res.data.applicants);
      setCurrentYear(res.data.selectableEntities.foundYear);
      setSelectedCompanyLocation(res.data.selectableEntities.foundCompanyLocationId ?? "undefined");
      setLoading(false);
      setInitJustLoaded(true);
    }
  }, [apiRequest]);

  const getStatistics = useCallback(async () => {
    if (!currentYear) return;
    if (initJustLoaded) {
      setInitJustLoaded(false);
      return;
    }
    setLoading(true);
    const yearAddOn = currentYear === "all" ? "" : `?year=${currentYear}`;
    const url = selectedCompanyLocation
      ? `statistics/${selectedCompanyLocation === "undefined" ? "" : selectedCompanyLocation}${yearAddOn}`
      : `statistics${yearAddOn}`;
    const res = await apiRequest<StatisticsResponseDto>(url, "GET");
    if (res.data && listEntities && selectableEntities) {
      setStatistics(
        mapStatisticsWithTitles(
          {
            bar: res.data.bar,
            circle: res.data.circle,
          },
          listEntities
        )
      );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRequest, currentYear, listEntities, selectableEntities, selectedCompanyLocation]);

  useEffect(() => {
    initRequest();
  }, [initRequest]);

  useEffect(() => {
    getStatistics();
  }, [getStatistics, currentYear, selectedCompanyLocation]);

  return (
    <>
      <Flex direction="column" padding="1rem 1.5rem" className="h-full">
        <Flex width="100%" justifyContent="space-between" margin="0 0 1em">
          <Heading size="lg">Mein Bewerbercockpit</Heading>
        </Flex>
        <Tabs position="relative" height="100%" flex="1" display="flex" flexDirection="column" isLazy>
          <TabList>
            {selectableEntities?.years.map((year, index) => (
              <Tab sx={{ color: "#7C8080" }} key={index} onClick={() => setCurrentYear(year)}>
                {year === "all" ? "Gesamt" : year}
              </Tab>
            ))}
          </TabList>
          <TabPanels display="flex" flexDirection="column" flex="1">
            {selectableEntities?.years.map((year) => (
              <TabPanel height="100%" padding="1.5rem 0" key={year}>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {statistics && applicants && listEntities ? (
                      <Flex className="flex-col gap-2">
                        <Select
                          onValueChange={(value) => {
                            setSelectedCompanyLocation(value);
                          }}
                          value={
                            selectedCompanyLocation
                              ? selectedCompanyLocation
                              : selectableEntities.foundCompanyLocationId ?? selectableEntities.allowedAll
                              ? "undefined"
                              : undefined
                          }
                        >
                          <SelectTrigger className="w-[250px]">
                            <SelectValue placeholder="Standort auswählen" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {selectableEntities.allowedAll && (
                                <SelectItem value={"undefined"}>Alle Standortzuweisungen</SelectItem>
                              )}
                              {selectableEntities.companyLocations.map((location) => (
                                <SelectItem key={location._id} value={location._id}>
                                  {location.value}
                                </SelectItem>
                              ))}
                              {user?.permittedContentWithoutCompanyLocationAssignment && (
                                <SelectItem value={"none"}>Kein Standort</SelectItem>
                              )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <PageContent statistics={statistics} applicants={applicants} listEntities={listEntities} />
                      </Flex>
                    ) : (
                      <div>Something went wrong</div>
                    )}
                  </>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
};

const PageContent = ({
  statistics,
  applicants,
  listEntities,
}: {
  statistics: MappedStatisticsDto;
  applicants: ApplicantsResponseDto;
  listEntities: ListEntitiesResponseDto;
}) => {
  const getStatus = (statusId: string) => {
    return (
      listEntities.status.find((status) => status.id === statusId) ?? {
        value: "Nicht zugewiesen",
        color: "#000",
      }
    );
  };

  const formatDate = (date: Date): string => {
    const now = new Date();
    const diffInMilliseconds = now.getTime() - date.getTime();
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
    const diffInHours = Math.floor(diffInMilliseconds / (60000 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (60000 * 60 * 24));
    if (diffInDays < 0) {
      return "Datum in der Zukunft";
    } else if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    ) {
      return "Heute";
    } else if (diffInMinutes < 60) {
      return diffInMinutes === 1 ? "vor 1 Minute" : `vor ${diffInMinutes} Minuten`;
    } else if (diffInHours < 24) {
      return diffInHours === 1 ? "vor 1 Stunde" : `vor ${diffInHours} Stunden`;
    } else if (diffInDays === 1) {
      return "Gestern";
    } else if (diffInDays <= 7) {
      return `vor ${diffInDays} Tagen`;
    } else if (diffInDays <= 30) {
      const diffInWeeks = Math.floor(diffInDays / 7);
      return diffInWeeks === 1 ? "vor 1 Woche" : `vor ${diffInWeeks} Wochen`;
    } else {
      return formatDistanceToNow(date, { locale: de, addSuffix: true });
    }
  };

  return (
    <Flex className="h-full gap-12">
      <Flex className="flex-col gap-5 w-[70%]">
        <Card>
          <CardHeader>
            <Flex justify="space-between">
              <Heading size="md">Eingegangene Bewerbungen</Heading>
            </Flex>
          </CardHeader>
          <CardBody>
            <BarChartUsageExampleWithClickEvent data={statistics.bar} />
          </CardBody>
        </Card>
        <div className="flex flex-col gap-3">
          <Heading size="md">Bewerberstatistik</Heading>
          <div className="gap-6 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3">
            <Card className="w-full">
              <CardBody className="" padding=".5em .25em">
                <DonutChartUsageExample data={statistics.circle.applicantsByStatus} title="Status" />
              </CardBody>
            </Card>
            <Card className="w-full ">
              <CardBody className="" padding=".5em .25em">
                <DonutChartUsageExample data={statistics.circle.applicantsByJobOffer} title="Stellen" />
              </CardBody>
            </Card>
            <Card className="w-full ">
              <CardBody className="" padding=".5em .25em">
                <DonutChartUsageExample data={statistics.circle.applicantsByLocation} title="Standorte" />
              </CardBody>
            </Card>
            <Card className=" w-full ">
              <CardBody className="" padding=".5em .25em">
                <DonutChartUsageExample data={statistics.circle.applicantsBySource} title="Quellen" />
              </CardBody>
            </Card>
          </div>
        </div>
      </Flex>
      <Flex className="w-[30%] flex-col gap-5">
        <Heading size="md">Neueste Bewerbungen</Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap="4">
          {applicants.applicants.map((bewerbung, index) => {
            const status = getStatus(bewerbung.belongsToStatus);

            return (
              <Card key={index} className="bg-green-100 max-w-64 min-w-48">
                <Link to={`/bewerber/${bewerbung._id}`} className="grow">
                  <CardBody padding=".5em" className="h-full">
                    <Flex className="flex-col justify-between h-full">
                      <Box className="mb-3 mt-1 flex gap-2 flex-col items-start">
                        <Flex justify="space-between" alignItems="center">
                          <Heading size="xs">{bewerbung.name}</Heading>
                        </Flex>
                        <Box className="max-w-[-webkit-fill-available]">
                          <Flex
                            className={`px-2 py-1 border rounded-full text-[10px] items-center text-center font-semibold text-ellipsis overflow-hidden max-w-[-webkit-fill-available]`}
                            color={status.color}
                            bg={`${status.color}10`}
                            border={`${status.color} 1px solid`}
                          >
                            <p className="overflow-hidden text-left inline-block whitespace-nowrap text-ellipsis	">
                              {status.value}
                            </p>
                          </Flex>
                        </Box>
                      </Box>
                      {bewerbung.jobOfferTitle && (
                        <Text fontSize="xs" className="text-slate-400">
                          {bewerbung.jobOfferTitle}
                        </Text>
                      )}
                      <Flex className="mt-2 justify-between w-full">
                        {bewerbung.companyLocationTitle ? (
                          <Box className="text-slate-400 px-3 py-1 text-xs border rounded-full items-center">
                            <Text className="whitespace-nowrap overflow-hidden text-ellipsis">
                              {bewerbung.companyLocationTitle}
                            </Text>
                          </Box>
                        ) : (
                          <div></div>
                        )}
                        <Text fontSize="xs" className="text-slate-400 ml-2 self-flex-end">
                          {formatDate(new Date(bewerbung.applicationDate))}
                        </Text>
                      </Flex>
                    </Flex>
                  </CardBody>
                </Link>
              </Card>
            );
          })}
        </Grid>
        <Flex className="rounded-lg bg-gradient-to-br from-[#330066] to-[#2b7cb1] justify-between py-4 px-8 text-white items-center flex-wrap gap-y-3 gap-x-8">
          <Flex gap="3" alignItems="center">
            <PeopleGroupIcon />
            <Box className="text-3xl font-bold">{applicants.totalApplicationsThisYear}</Box>
            <Box className="text-sm leading-4">
              Bewerbungen seit
              <br />
              Beginn des Jahres
            </Box>
          </Flex>
          <Link to="/bewerber" className="grow">
            <Button colorScheme="white" color="white" className="flex gap-2 p-2 w-full" variant="outline" size="sm">
              <span>Alle ansehen</span>
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

const parseBarKey = (key: string) => {
  switch (key) {
    case "lastSevenDays":
      return "Letzte 7 Tage";
    case "lastFourWeeks":
      return "Letzte 4 Wochen";
    case "lastThreeMonths":
      return "Letzte 3 Monate";
    default:
      return key;
  }
};

const mapStatisticsWithTitles = (
  statistics: StatisticsResponseDto,
  listEntities: ListEntitiesResponseDto
): MappedStatisticsDto => {
  return {
    bar: {
      barDiagrams: statistics.bar.barDiagrams.map((bar) => {
        const mapped: MappedBarDiagramResponseDto = {
          ...bar,
          title: parseBarKey(bar.key),
        };
        return mapped;
      }),
    },
    circle: {
      applicantsByJobOffer: statistics.circle.applicantsByJobOffer.map((circle) => {
        const mapped: MappedCircleDiagramResponseDto = {
          ...circle,
          title: circle.title ?? "Nicht zugewiesen",
        };
        return mapped;
      }),
      applicantsByStatus: statistics.circle.applicantsByStatus.map((circle) => {
        const mapped: MappedCircleDiagramResponseDto = {
          ...circle,
          title: listEntities.status.find((s) => s.id === circle._id)?.value ?? "Nicht zugewiesen",
        };
        return mapped;
      }),
      applicantsBySource: statistics.circle.applicantsBySource.map((circle) => {
        const mapped: MappedCircleDiagramResponseDto = {
          ...circle,
          title: listEntities.sources.find((s) => s.id === circle._id)?.value ?? "Nicht zugewiesen",
        };
        return mapped;
      }),
      applicantsByLocation: statistics.circle.applicantsByLocation.map((circle) => {
        const mapped: MappedCircleDiagramResponseDto = {
          ...circle,
          title: circle.title ?? "Nicht zugewiesen",
        };
        return mapped;
      }),
    },
  };
};

interface DonutChartUsageExampleProps {
  data: MappedCircleDiagramResponseDto[];
  title: string;
}

const formatDonutData = (data: MappedCircleDiagramResponseDto[]) => {
  return data.map((item) => {
    return {
      title: item.title ? (item.title === "None" ? "Nicht zugewiesen" : item.title) : "Nicht zugewiesen",
      count: item.applicationCount,
    };
  });
};

const formatGraphData = (data: MappedBarDiagramResponseDto[]) => {
  return data.map((item) => {
    return {
      title: item.title
        ? item.title === "None"
          ? "Nicht zugewiesen"
          : item.title
        : item.title === "others"
        ? "Andere"
        : "Nicht zugewiesen",
      key: item.key,
      applicationCount: item.applicationCount,
    } as MappedBarDiagramResponseDto;
  }) as MappedBarDiagramResponseDto[];
};

type OutputItem = {
  category: string;
  [key: string]: number | string;
};

const transformData = (input: MappedBarDiagramResponseDto[]) => {
  const output: OutputItem[] = [];
  const categories: string[] = [];

  input.forEach((item) => {
    const result: OutputItem = { category: parseBarKey(item.key) };

    if (item.applicationCount !== 0) {
      result[item.title] = item.applicationCount;
    }

    if (!categories.includes(item.title)) {
      categories.push(item.title);
    }

    output.push(result);
  });

  return { output, categories };
};

export function BarChartUsageExampleWithClickEvent({ data }: { data: { barDiagrams: MappedBarDiagramResponseDto[] } }) {
  const [value, setValue] = useState<EventProps>(null);

  const { output, categories } = transformData(formatGraphData(data.barDiagrams));

  const error = console.error;
  console.error = (...args: any) => {
    if (/defaultProps/.test(args[0])) return;
    error(...args);
  };

  useEffect(() => {
    // console.log("Value changed", value);
  }, [value]);

  return (
    <>
      <BarChart
        className="mt-6"
        data={output}
        index="category"
        categories={categories}
        yAxisWidth={30}
        onValueChange={(v) => setValue(v)}
        colors={statColors}
      />
    </>
  );
}

export function DonutChartUsageExample({ data, title }: DonutChartUsageExampleProps) {
  const formatted = formatDonutData(data);

  return (
    <>
      <div className="flex items-center gap-4">
        <DonutChart
          label={title}
          data={formatted}
          category={"count"}
          index="title"
          className="min-w-32 w-32 font-semibold ml-2"
          colors={statColors}
          noDataText="Keine Daten"
        />
        <div className="flex flex-col break-all	">
          {formatted.map((item, i) => (
            <div key={item.title + i} className="flex items-center gap-2">
              <div className={`text-[${statColors[i]}] font-bold text-lg text-right`}>{item.count}</div>
              <div className={`text-sm font-semibold`}>
                <p
                  className="overflow-hidden"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.title}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export { Home };
