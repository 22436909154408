import { Flex, Heading, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import "./styles.css";

import {
  MailTemplateLabelResponseDto,
  MailTemplateResponseDto,
  MailTemplateWithLabelsResponseDto,
} from "@/lib/interfaces/mail";
import { useCallback, useEffect, useState } from "react";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useApiRequest from "../../lib/hooks/useRequest";
import EmailTabPanel from "./EmailTabPanel";
import FirstTabPanel from "./FirstTabPanel";
import { useUser } from "../../hooks/use-user";
import NoPermissionRead from "../../ui/components/ui/NoPermission";

export const EmailDispatchParent = () => {
  const { user } = useUser();
  if (!user) return null;
  return (
    <>
      {user.permissionMailTemplates === "NONE" && !user.permissionOrganizationAdmin ? (
        <NoPermissionRead title="Email Automatisation" />
      ) : (
        <EmailDispatch />
      )}
    </>
  );
};

const EmailDispatch = () => {
  const { apiRequest } = useApiRequest();

  const [emailLabels, setEmailLabels] = useState<MailTemplateLabelResponseDto[]>();
  const [firstTab, setFirstTab] = useState<MailTemplateResponseDto | undefined>();
  const [placeholders, setPlaceholders] = useState<string[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  const getEmailLabels = useCallback(async () => {
    const response = await apiRequest<MailTemplateWithLabelsResponseDto>("mail-templates", "GET");

    if (response?.data) {
      setEmailLabels(response.data.labels);
      setFirstTab(response.data.mailTemplates[0]);
      setPlaceholders(response.data.placeholders ?? []);
    } else {
      // Should return some error and hide spinner / preloader
      setEmailLabels(undefined);
    }
  }, [apiRequest]);

  useEffect(() => {
    // get organization details from API
    getEmailLabels();
  }, [apiRequest, getEmailLabels]);

  useEffect(() => {
    if (reload) {
      getEmailLabels();
      setReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Flex direction="column" padding="1rem 1.5rem" className="h-full">
      <Heading size="lg" marginBottom="1.5rem">
        Email-Vorlagen
      </Heading>
      {emailLabels ? (
        <Tabs position="relative" height="100%" flex="1" display="flex" flexDirection="column" isLazy>
          <TabList>
            {emailLabels.map((item) => {
              return (
                <Tab sx={{ color: "#7C8080" }} key={item.id}>
                  {item.value}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels display="flex" flexDirection="column" flex="1">
            <TabPanel height="100%" padding="1.5rem 0">
              <FirstTabPanel mailTemplate={firstTab} placeholders={placeholders} setReload={setReload} />
            </TabPanel>
            {emailLabels.slice(1).map((item) => {
              return (
                <TabPanel height="100%" key={item.id} padding="1.5rem 0">
                  <EmailTabPanel mailLabel={item.id} placeholders={placeholders} />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      ) : (
        <Flex className="justify-center w-full h-52 items-center">
          <Spinner size="xl" color="primary.500" />
        </Flex>
      )}
    </Flex>
  );
};

export { EmailDispatch };
