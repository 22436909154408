interface AppStatusProps {
  status: string;
  color: string;
}

const AppStatus = ({ status, color }: AppStatusProps) => {
  return (
    <div
      className={`text-xs bg-opacity-5 border rounded-xl px-2 py-1 text-center`}
      style={{
        color: color,
        borderColor: color,
      }}
      // className={`text-xs text-status-${status} bg-status-${status} bg-opacity-5 border rounded-xl px-2 py-1 border-status-${status}`}
    >
      {status}
    </div>
  );
};

export default AppStatus;
