import { UserInvitationOptionalResponseDto } from "@/lib/interfaces/invitation";
import { UserInformationResponseDto } from "@/lib/interfaces/user";
import { useAuth0 } from "@auth0/auth0-react";
import { Heading, Spinner } from "@chakra-ui/react";
import React, { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useApiRequest from "../../lib/hooks/useRequest";
import ErrorModal from "../../ui/components/ui/error-modal";

interface IContext {
  isMobile: boolean;
  accessToken: string;
  isBanned: boolean;
  user?: UserInformationResponseDto; // UserPrivateResponseDto
  error?: Error;
  setIsBanned?: Dispatch<SetStateAction<boolean>>;
  setAccessToken?: Dispatch<SetStateAction<string>>;
  setUser?: Dispatch<SetStateAction<UserInformationResponseDto | undefined>>;
  setError?: Dispatch<SetStateAction<Error | undefined>>;
}

export enum ErrorEntity {
  CompanyLocation = "CompanyLocation",
  MissingUserEmailConflict = "MissingUserEmailConflict",
  NoOrganizationForbidden = "NoOrganizationForbidden",
  NoUserFoundForbidden = "NoUserFoundForbidden",
  ForbiddenException = "ForbiddenException",
  ConflictException = "ConflictException",
  NoSubscriptionForbidden = "NoSubscriptionForbidden",
  InactiveSubscriptionConflict = "InactiveSubscriptionConflict",
  InsufficientSubscriptionForbidden = "InsufficientSubscriptionForbidden",
  InactiveSubscriptionForbidden = "InactiveSubscriptionForbidden",
  CheckInvite = "CheckInvite",
  Conflict = "Conflict",
  MFANotAuthorizedForbidden = "MFANotAuthorizedForbidden",
  EMailNotVerifiedForbidden = "EMailNotVerifiedForbidden",
  InsufficientSubscriptionTooManyUnarchivedJobOffersForbidden = "InsufficientSubscriptionTooManyUnarchivedJobOffersForbidden",
  NoSuperAdminPermission = "NoSuperAdminPermission",
}

export interface Error {
  message: string;
  error: string;
  statusCode: number;
  entity: ErrorEntity;
  actionLocation?: string;
  isClosable?: boolean;
  withReload?: boolean;
  mail?: string;
}

export const Context = React.createContext<IContext>({
  isMobile: false,
  accessToken: "",
  isBanned: false,
});

export const ContextProvider = ({ children }: any) => {
  const setSessionToken = () => {
    const currentSessionToken = sessionStorage.getItem("session_token");
    if (!currentSessionToken) {
      sessionStorage.setItem("session_token", uuidv4());
    }
  };

  setSessionToken();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [accessToken, setAccessToken] = useState<string>("");
  const [user, setUser] = useState<any | undefined>();
  const [isBanned, setIsBanned] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const auth0 = useAuth0();

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (setAccessToken) {
        const x = await auth0.getAccessTokenSilently({
          authorizationParams: { scope: "openid profile email offline_access" },
        });
        setAccessToken(x);
      }
    };
    if (auth0.isAuthenticated) {
      fetchAccessToken();
    } else if (!auth0.isLoading) {
      const login = async () => {
        await auth0.loginWithRedirect({
          appState: {
            returnTo: "/",
          },
        });
      };
      if (
        !window.location.href.includes("/register") &&
        !window.location.href.includes("/invite") &&
        !window.location.href.includes("/reset")
      ) {
        login();
      }
      return;
    }
  }, [auth0, auth0.isAuthenticated]);

  const value = {
    isMobile,
    accessToken,
    user,
    isBanned,
    setAccessToken,
    setUser,
    setIsBanned,
    error,
    setError,
  };

  return (
    <Context.Provider value={value}>
      <OrganizationFetch>
        {children}
        <ErrorModal />
      </OrganizationFetch>
    </Context.Provider>
  );
};

const OrganizationFetch = ({ children }: { children: ReactNode }) => {
  const { apiRequest } = useApiRequest();
  const { accessToken, setUser, user, setError } = useContext(Context);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const implemented = true;

  const getOrganization = async () => {
    if (
      window.location.href.includes("/register") ||
      window.location.href.includes("/invite") ||
      window.location.href.includes("/reset")
    ) {
      setIsFinished(true);
      return;
    }
    if (accessToken && setUser && !user && implemented) {
      try {
        const res = await apiRequest<UserInformationResponseDto>("users/userinfo", "GET");
        if (res.data) {
          setUser(res.data);
        }
        if (setError) {
          const res = await apiRequest<UserInvitationOptionalResponseDto>("organizations/invitations", "GET");
          if (res.data?.invitation) {
            setError({
              entity: ErrorEntity.CheckInvite,
              error: "CheckInvite",
              message: "CheckInvite",
              statusCode: 200,
            });
          }
        }
        setIsFinished(true);
      } catch (e: any) {
        // user setup is not complete
      }
    }
  };

  useEffect(() => {
    getOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, user]);

  if (!implemented) {
    return <>{children}</>;
  }

  return <> {isFinished ? children : <Loading />}</>;
};

const Loading = () => {
  return (
    <div className="h-[100vh] w-[100vw] flex justify-center">
      <div className="m-auto flex flex-col gap-2 p-8">
        <Heading size="xl" className="-mt-20">
          Loading
        </Heading>
        <Spinner className="m-auto" />
      </div>
    </div>
  );
};
