import { useParams } from "react-router-dom";
import { Invitation as InvitationComponent } from "../components/invitation/Invitation";

const Register = () => {
  const { uuid } = useParams();

  return <InvitationComponent uuid={uuid} />;
};

export default Register;
