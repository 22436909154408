import { OrganizationResponseDto } from "@/lib/interfaces/organizations";
import CompanyForm from "./CompanyForm";

const CompanySettings = ({ organization }: { organization: OrganizationResponseDto }) => {
  return (
    <>
      <CompanyForm organization={organization} />
    </>
  );
};

export default CompanySettings;
