import {
  ColumnDef,
  // ColumnFiltersState,
  FilterFn,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Archive, ChevronDown, Info, MoreHorizontal } from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Button as ChakraButton, Flex, Spinner, Text, Switch } from "@chakra-ui/react";
import { Button } from "../../ui/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/components/ui/dropdown-menu";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/components/ui/table";
// import GlobalFilter from "./GlobalFilter";
import { CompanyLocationResponseDto } from "@/lib/interfaces/locations";
import { Paginated } from "@/lib/interfaces/utils";
import { Link, useNavigate } from "react-router-dom";
import useApiRequest from "../../lib/hooks/useRequest";
import { useUser } from "../../hooks/use-user";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/components/ui/dialog";

const columnNames: { [key: string]: string } = {
  title: "Bezeichnung",
  street: "Adresse",
  applicantCount: "Anzahl Bewerbungen",
  applicantsContactName: "Ansprechpartner",
  jobOfferCount: "Anzahl Stellenanzeigen",
  unarchivedJobOfferCount: "Nicht archivierte Stellenanzeigen",
};

const globalFilterFunction: FilterFn<CompanyLocationResponseDto> = (row, columnId, filterValue) => {
  return Object.values(row.original).some((value) =>
    String(value).toLowerCase().includes(String(filterValue).toLowerCase())
  );
};

// export function DataTable({ data }: { data: CompanyLocationResponseDto[] }) {
export function DataTable({
  limitiations,
  setLimitations,
}: {
  limitiations:
    | {
        allowedElementsCount: number;
        usedElementsCount: number;
      }
    | undefined;
  setLimitations: Dispatch<
    SetStateAction<
      | {
          allowedElementsCount: number;
          usedElementsCount: number;
        }
      | undefined
    >
  >;
}) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [archivedData, setArchivedData] = useState<boolean>(false);
  const { user } = useUser();
  const [dialog, setDialog] = useState<{
    isOpen: boolean;
    applicantId: string;
  }>();
  const [reload, setReload] = useState(false);

  const columns: ColumnDef<CompanyLocationResponseDto>[] = [
    {
      accessorKey: "title",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.title}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.title}</Text>
        );
      },
      cell: ({ row }) => {
        const location = row.original;
        if (archivedData) {
          return <Text>{row.getValue("title")}</Text>;
        }
        return <Link to={`/standorte/${location._id}`}>{row.getValue("title")}</Link>;
      },
    },
    {
      accessorKey: "street",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.street}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.street}</Text>
        );
      },
    },
    {
      accessorKey: "applicantCount",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.applicantCount}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.applicantCount}</Text>
        );
      },
    },
    {
      accessorKey: "applicantsContactName",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.applicantsContactName}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.applicantsContactName}</Text>
        );
      },
    },
    {
      accessorKey: "jobOfferCount",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.applicantsContactName}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.jobOfferCount}</Text>
        );
      },
    },
    {
      accessorKey: "unarchivedJobOfferCount",
      header: ({ column }) => {
        return (
          // <Text className="cursor-pointer" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          //   {columnNames.applicantsContactName}
          //   {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""}
          // </Text>
          <Text>{columnNames.unarchivedJobOfferCount}</Text>
        );
      },
    },
    {
      id: "actions",
      header: "Details / Anpassung",
      enableHiding: false,
      cell: ({ row }) => {
        const location = row.original;
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="flex flex-col p-3 gap-2">
              {/* <DropdownMenuLabel>Actions</DropdownMenuLabel> */}
              {!archivedData && (
                <DropdownMenuItem className="flex gap-2 cursor-pointer">
                  <Link to={`/standorte/${location._id}`} className="flex gap-2">
                    <Info className="h-4 w-4 text-primary self-center" />
                    Details
                  </Link>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                className="flex gap-2 cursor-pointer"
                disabled={user?.permissionCompanyLocations !== "FULL" && !user?.permissionOrganizationAdmin}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!archivedData) {
                    setDialog({
                      isOpen: true,
                      applicantId: location._id,
                    });
                  } else {
                    await archiveToggle(location._id);
                  }
                }}
              >
                <Archive className="h-4 w-4 text-primary" />
                {!archivedData ? "Archivieren" : "Wiederherstellen"}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  const archiveToggle = async (id: string) => {
    if (id && data) {
      const res = await apiRequest(`company-locations/${id}/${archivedData ? "unarchive" : "archive"}`, "PATCH");
      if (res.status < 300 && res.status >= 200) {
        setData((jobOffers) => jobOffers.filter((item) => item._id !== id));
        setReload((prev) => !prev);
      }
    }
  };

  // const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();

  const [data, setData] = useState<CompanyLocationResponseDto[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // fetch the data
  // as soon as it is fetched set the data to the table

  const table = useReactTable({
    data,
    columns,
    // pageCount: Math.ceil(data.length / 10),
    pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    globalFilterFn: globalFilterFunction,
    onGlobalFilterChange: setGlobalFilter,
    initialState: { pagination: { pageSize: 5 }, globalFilter },
    state: {
      sorting,
      pagination,
      // columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
  });

  useEffect(() => {
    // get locations from the API
    const getLocations = async () => {
      setIsLoading(true);
      const response = await apiRequest<Paginated<CompanyLocationResponseDto>>(
        `company-locations/${archivedData ? "archive" : ""}?limit=${pagination.pageSize}&page=${
          pagination.pageIndex + 1
        }`,
        "GET"
      );
      if (response?.data?.docs) {
        setData(response.data?.docs);
        setPageCount(Math.ceil(response.data.count / pagination.pageSize));
        setItemCount(response.data.count);
        if (response.data.allowedElementsCount !== undefined && response.data.usedElementsCount !== undefined) {
          setLimitations({
            allowedElementsCount: response.data.allowedElementsCount,
            usedElementsCount: response.data.usedElementsCount,
          });
        } else {
          setLimitations({
            allowedElementsCount: response.data.allowedElementsCount ?? -1,
            usedElementsCount: response.data.usedElementsCount ?? 0,
          });
        }
      } else {
        // Should return some error and hide spinner / preloader
        setData([]);
      }
      setIsLoading(false);
    };
    getLocations();
  }, [apiRequest, archivedData, pagination.pageIndex, pagination.pageSize, setLimitations, reload]);

  return (
    <div className="w-full">
      <Flex className="items-center py-4 gap-4">
        {dialog?.applicantId && (
          <Dialog open={dialog?.isOpen} onOpenChange={() => setDialog({ isOpen: false, applicantId: "" })}>
            <X handleArchiveClick={archiveToggle} id={dialog.applicantId} />
          </Dialog>
        )}
        <div className="mb-2">
          <Switch
            sx={{ "span.chakra-switch__track:not([data-checked])": { backgroundColor: "gray.400" } }}
            isChecked={archivedData}
            onChange={() => setArchivedData(!archivedData)}
            display="flex"
            alignItems="center"
          >
            Archivierte anzeigen
          </Switch>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Spalten <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(!!value)}
                  >
                    {columnNames[column.id.toString()]}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>
      <div className="rounded-md border overflow-hidden">
        {isLoading ? (
          <Flex className="justify-center w-full h-36 items-center bg-white">
            <Spinner size="xl" color="primary.500" />
          </Flex>
        ) : (
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row, i) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={`hover:bg-slate-100 ${!archivedData && "cursor-pointer"} ${
                      !archivedData ? "opacity-100" : "opacity-50"
                    }`}
                    onClick={() => {
                      if (!archivedData) {
                        navigate(`/standorte/${row.original._id}`);
                      }
                    }}
                  >
                    {row.getVisibleCells().map((cell, j) => (
                      <TableCell key={cell.id} className={`${j === 0 ? " font-bold" : ""}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    Keine Resultate.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
      <div className="flex justify-between items-center">
        {limitiations && (
          <Flex
            className={`${
              limitiations.allowedElementsCount === -1
                ? "bg-green-100 text-green-700"
                : limitiations.allowedElementsCount - limitiations.usedElementsCount === 1
                ? "bg-orange-100 text-orange-700"
                : limitiations.allowedElementsCount <= limitiations.usedElementsCount
                ? "bg-red-100 text-red-700"
                : "bg-green-100 text-green-700"
            } rounded-md w-fit p-2`}
          >
            <Text fontSize="sm">
              {limitiations.usedElementsCount} von{" "}
              {limitiations.allowedElementsCount === -1 ? "∞" : limitiations.allowedElementsCount} Standorten genutzt
            </Text>
          </Flex>
        )}
        <div className="py-4 flex flex-col gap-2">
          <div className="text-sm text-end">{itemCount} Ergebnisse gesamt</div>
          <div className="flex items-center justify-end gap-6 ">
            <div className="flex space-x-2 items-center">
              <Text fontSize="medium">
                Seite {pagination.pageIndex + 1} von {pageCount}
              </Text>
              <Button
                variant="outline"
                size="sm"
                className="text-base"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                zurück
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                className="text-base"
              >
                weiter
              </Button>
            </div>
            <Flex alignItems="center">
              <Text fontSize="medium">Zeigen</Text>
              <select
                className="py-1 pr-7 pl-3 ml-2 rounded-sm border-gray-200 text-sm"
                value={pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 15, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
}

const X = ({ handleArchiveClick, id }: { handleArchiveClick: (id: string) => void; id: string }) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Standort archivieren?</DialogTitle>
        <DialogDescription>
          Wird der Standort archiviert, so kann dieser nicht mehr für neu eingehende Bewerber genutzt werden und alle
          Stellenanzeigen des Standorts werden automatisch inaktiv geschalten und gelten ebenfalls als archiviert. Dies
          kann Auswirkungen auf bestehende Zapier oder Make Integrationen haben.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose asChild>
          <ChakraButton
            colorScheme="primary"
            className="flex gap-2 p-2 text-white"
            justifyContent="flex-start"
            variant="outline"
          >
            Schliessen
          </ChakraButton>
        </DialogClose>
        <DialogClose asChild>
          <ChakraButton
            borderColor="error"
            textColor="error"
            className="flex gap-2 p-2"
            justifyContent="flex-start"
            variant="outline"
            onClick={() => handleArchiveClick(id)}
          >
            Archivieren
          </ChakraButton>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};
