const GutIcon = ({ selected }: { selected: boolean }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.875 11.25C17.3958 11.25 17.8385 11.0677 18.2031 10.7031C18.5677 10.3386 18.75 9.89581 18.75 9.375C18.75 8.85419 18.5677 8.41144 18.2031 8.04688C17.8385 7.68231 17.3958 7.5 16.875 7.5C16.3542 7.5 15.9115 7.68231 15.5469 8.04688C15.1823 8.41144 15 8.85419 15 9.375C15 9.89581 15.1823 10.3386 15.5469 10.7031C15.9115 11.0677 16.3542 11.25 16.875 11.25ZM8.125 11.25C8.64584 11.25 9.08854 11.0677 9.45312 10.7031C9.81771 10.3386 10 9.89581 10 9.375C10 8.85419 9.81771 8.41144 9.45312 8.04688C9.08854 7.68231 8.64584 7.5 8.125 7.5C7.60416 7.5 7.16146 7.68231 6.79688 8.04688C6.43229 8.41144 6.25 8.85419 6.25 9.375C6.25 9.89581 6.43229 10.3386 6.79688 10.7031C7.16146 11.0677 7.60416 11.25 8.125 11.25ZM12.5 19.375C13.9167 19.375 15.2031 18.9739 16.3594 18.1719C17.5156 17.3698 18.3542 16.3125 18.875 15H16.8125C16.3542 15.7708 15.7448 16.3802 14.9844 16.8281C14.224 17.2761 13.3958 17.5 12.5 17.5C11.6042 17.5 10.776 17.2761 10.0156 16.8281C9.25521 16.3802 8.64584 15.7708 8.1875 15H6.125C6.64584 16.3125 7.48438 17.3698 8.64062 18.1719C9.79688 18.9739 11.0833 19.375 12.5 19.375ZM12.5 25C10.7708 25 9.14584 24.6719 7.625 24.0156C6.10416 23.3594 4.78125 22.4688 3.65625 21.3438C2.53125 20.2188 1.64062 18.8958 0.984375 17.375C0.328125 15.8542 0 14.2292 0 12.5C0 10.7708 0.328125 9.14581 0.984375 7.625C1.64062 6.10419 2.53125 4.78125 3.65625 3.65625C4.78125 2.53125 6.10416 1.64062 7.625 0.984375C9.14584 0.328125 10.7708 0 12.5 0C14.2292 0 15.8542 0.328125 17.375 0.984375C18.8958 1.64062 20.2188 2.53125 21.3438 3.65625C22.4688 4.78125 23.3594 6.10419 24.0156 7.625C24.6719 9.14581 25 10.7708 25 12.5C25 14.2292 24.6719 15.8542 24.0156 17.375C23.3594 18.8958 22.4688 20.2188 21.3438 21.3438C20.2188 22.4688 18.8958 23.3594 17.375 24.0156C15.8542 24.6719 14.2292 25 12.5 25Z"
        fill={selected ? "#91BA07" : "#E5E5E5"}
      />
    </svg>
  );
};

export default GutIcon;
