import { Box, Button, Spinner } from "@chakra-ui/react";
import useApiRequest from "../../lib/hooks/useRequest";
import { DialogContent, DialogHeader, DialogTitle } from "../../ui/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Input } from "../../ui/components/ui/input";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

interface OrganisationDetails {
  firstName: string;
  lastName: string;
  adminMail: string;
}

const OrganisationEditorParent = ({
  id,
  setSelectedOrgId,
}: {
  id?: string;
  setSelectedOrgId: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const { apiRequest } = useApiRequest();
  const [selectedOrg, setSelectedOrg] = useState<OrganisationDetails>();
  const [loading, setLoading] = useState(id ? true : false);

  const getOrg = useCallback(async () => {
    if (id) {
      setLoading(true);
      const response = await apiRequest<OrganisationDetails>(`organizations/get/${id}`, "GET");
      if (response.data) {
        setSelectedOrg(response.data);
      }
      setLoading(false);
    }
  }, [apiRequest, id]);

  useEffect(() => {
    getOrg();
  }, [getOrg]);

  return (
    <>
      {loading ? (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Organisation bearbeiten</DialogTitle>
          </DialogHeader>
          <Spinner />
        </DialogContent>
      ) : selectedOrg && id ? (
        <OrganisationEditor item={selectedOrg} setSelectedOrg={setSelectedOrgId} id={id} />
      ) : (
        <OrganisationCreate />
      )}
    </>
  );
};

const OrganisationCreate = () => {
  const { apiRequest } = useApiRequest();

  const formSchema = z.object({
    name: z.string().min(1),
    street: z.string().min(1),
    postCode: z.string().min(1),
    placeName: z.string().min(1),
    ceo: z.string().min(1),
    adminMail: z.string().email({ message: "Bitte geben Sie eine gültige E-Mail-Adresse ein" }),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      street: "",
      postCode: "",
      placeName: "",
      ceo: "",
      adminMail: "",
      firstName: "",
      lastName: "",
    },
  });

  // define the onSubmit function to handle the form submission - executed when the submit button is clicked
  async function onSubmit(values: z.infer<typeof formSchema>) {
    // create the request object with the form values
    const req = {
      name: values.name,
      street: values.street,
      postCode: values.postCode,
      placeName: values.placeName,
      ceo: values.ceo,
      adminMail: values.adminMail,
      firstName: values.firstName,
      lastName: values.lastName,
    };

    // if the item does not exist, create a new item with a POST request
    const response = await apiRequest("organizations/create", "POST", {
      body: req,
      toast: { toastText: "Organisation wurde erfolgreich erstellt" },
    });

    if (!response.error) {
      window.location.reload();
    }
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Organisation erstellen</DialogTitle>
      </DialogHeader>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full h-full bg-white">
          <Box className="grid grid-cols-2 gap-3">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex gap-2 w-full flex-col">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Name der Organisation <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="street"
              render={({ field }) => (
                <FormItem className="flex gap-2 w-full flex-col">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Straße <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Straße" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="postCode"
              render={({ field }) => (
                <FormItem className="flex gap-2 w-full flex-col">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    PLZ <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="PLZ" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="placeName"
              render={({ field }) => (
                <FormItem className="flex gap-2 w-full flex-col">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Ort <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Ort" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="ceo"
              render={({ field }) => (
                <FormItem className="flex gap-2 w-full flex-col">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Geschäftsführer <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Geschäftsführer" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="adminMail"
              render={({ field }) => (
                <FormItem className="flex gap-2 w-full flex-col">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Admin E-Mail <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Admin E-Mail" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="flex gap-2 w-full flex-col">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Vorname <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Vorname" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem className="flex gap-2 w-full flex-col">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Nachname <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Nachname" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            <Box className="flex gap-4 mt-8">
              <Box className="flex gap-4">
                <Button type="submit" colorScheme="primary" className="flex gap-2 p-2 text-white" variant="solid">
                  <span>Organisation erstellen</span>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Form>
    </DialogContent>
  );
};

const OrganisationEditor = ({
  item,
  setSelectedOrg,
  id,
}: {
  item: OrganisationDetails;
  setSelectedOrg: Dispatch<SetStateAction<string | undefined>>;
  id: string;
}) => {
  const { apiRequest } = useApiRequest();

  const formSchema = z.object({
    adminMail: z.string().email({ message: "Bitte geben Sie eine gültige E-Mail-Adresse ein" }),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      adminMail: item.adminMail,
      firstName: item.firstName,
      lastName: item.lastName,
    },
  });

  // define the onSubmit function to handle the form submission - executed when the submit button is clicked
  async function onSubmit(values: z.infer<typeof formSchema>) {
    // create the request object with the form values
    const req = {
      adminMail: values.adminMail,
      firstName: values.firstName,
      lastName: values.lastName,
    };

    // if the item does not exist, create a new item with a POST request
    const response = await apiRequest(`organizations/update/${id}`, "PATCH", {
      body: req,
      toast: { toastText: "Organisation wurde erfolgreich bearbeitet" },
    });

    if (!response.error) {
      window.location.reload();
    }
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Organisation bearbeiten</DialogTitle>
      </DialogHeader>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full h-full bg-white">
          <Box className="grid grid-cols-1 gap-3">
            <div className="flex gap-3">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem className="flex gap-2 w-full flex-col">
                    <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                      Vorname <span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input placeholder="Vorname" {...field} />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem className="flex gap-2 w-full flex-col">
                    <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                      Nachname <span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input placeholder="Nachname" {...field} />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="adminMail"
              render={({ field }) => (
                <FormItem className="flex gap-2 w-full flex-col">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Admin E-Mail <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Admin E-Mail" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            <Box className="flex gap-4 mt-8">
              <Box className="flex gap-4">
                <Button type="submit" colorScheme="primary" className="flex gap-2 p-2 text-white" variant="solid">
                  <span>Organisation bearbeiten</span>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Form>
    </DialogContent>
  );
};

export default OrganisationEditorParent;
