import { SmallAddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DataTable } from "../components/super-admin/DataTable";
import OrganisationEditorParent from "../components/super-admin/OrganisationEditor";
import { useUser } from "../hooks/use-user";
import { Dialog } from "../ui/components/ui/dialog";
import NoPermissionRead from "../ui/components/ui/NoPermission";

const SuperAdmin = () => {
  const { user } = useUser();
  if (!user) return null;
  return <>{!user.showSuperadminPanel ? <NoPermissionRead title="Super Admin" /> : <SuperAdminDetails />}</>;
};

const SuperAdminDetails = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<string>();

  useEffect(() => {
    if (selectedOrg) {
      setDialogOpen(true);
    }
  }, [selectedOrg]);

  return (
    <>
      <Flex direction="column" padding="1rem 1.5rem">
        <Flex width="100%" justifyContent="space-between" margin="0 0 1.5em">
          <Heading size="lg">Super Admin</Heading>
          <Flex gap="1rem" className="flex-col lg:flex-row">
            <Button
              colorScheme="primary"
              className="flex gap-2 p-2 text-white"
              variant="solid"
              onClick={() => setDialogOpen(true)}
            >
              <Box
                className="text-primary"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bg="white"
                boxSize="1rem"
              >
                <SmallAddIcon />
              </Box>
              <span>Organisation erstellen</span>
            </Button>
          </Flex>
        </Flex>
        <DataTable setSelectedOrg={setSelectedOrg} />
        <Dialog
          open={dialogOpen}
          onOpenChange={(value) => {
            setDialogOpen(value);
            if (!value) {
              setSelectedOrg(undefined);
            }
          }}
        >
          <OrganisationEditorParent id={selectedOrg} setSelectedOrgId={setSelectedOrg} />
        </Dialog>
      </Flex>
    </>
  );
};

export default SuperAdmin;
