import { Flex, Spinner, Text } from "@chakra-ui/react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { InfoIcon, LogInIcon, MoreHorizontal } from "lucide-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Button } from "../../ui/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/components/ui/dropdown-menu";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/components/ui/table";
// import GlobalFilter from "./GlobalFilter";
import { OrganizationListResponseDto } from "@/lib/interfaces/organizations";
import { Paginated } from "@/lib/interfaces/utils";
import useApiRequest from "../../lib/hooks/useRequest";

const columnNames: { [key: string]: string } = {
  name: "Unternehmen",
  ceo: "CEO",
};

export const DataTable = ({ setSelectedOrg }: { setSelectedOrg: Dispatch<SetStateAction<string | undefined>> }) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const { apiRequest } = useApiRequest();
  const [isLoading, setIsLoading] = useState(false);

  const login = async (id: string) => {
    await apiRequest(`organizations/${id}/login`, "POST");
    window.location.reload();
  };

  const columns: ColumnDef<OrganizationListResponseDto>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => {
        return <Text>{columnNames.name}</Text>;
      },
      cell: ({ row }) => {
        return <p>{row.getValue("name")}</p>;
      },
    },
    {
      accessorKey: "ceo",
      header: ({ column }) => {
        return <Text>{columnNames.ceo}</Text>;
      },
      cell: ({ row }) => {
        return <p>{row.getValue("ceo")}</p>;
      },
    },
    {
      accessorKey: "subscriptionStatus",
      header: "Status",
      cell: ({ row }) => {
        const value = row.getValue("subscriptionStatus");
        return (
          <p
            className={`${value ? "" : "italic bg-red-200 border-red-500 p-1 text-red-500 w-fit rounded-full border"}`}
          >
            {row.getValue("subscriptionStatus") ?? "nicht vorhanden"}
          </p>
        );
      },
    },
    {
      accessorKey: "subscriptionId",
      header: "Id",
      cell: ({ row }) => {
        const value = row.getValue("subscriptionId");
        return (
          <p
            className={`${value ? "" : "italic bg-red-200 border-red-500 p-1 text-red-500 w-fit rounded-full border"}`}
          >
            {row.getValue("subscriptionId") ?? "nicht vorhanden"}
          </p>
        );
      },
    },
    {
      id: "actions",
      header: "Details",
      enableHiding: false,
      cell: ({ row, cell }) => {
        const orga = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem className="flex gap-2 cursor-pointer" onClick={() => setSelectedOrg(orga._id)}>
                <InfoIcon className="h-4 w-4 text-primary self-center" />
                Details
              </DropdownMenuItem>
              <DropdownMenuItem
                className="flex gap-2 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  login(orga._id);
                }}
              >
                <LogInIcon className="h-4 w-4 text-primary self-center" />
                Einloggen{" "}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  const [data, setData] = useState<OrganizationListResponseDto[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [, setItemCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // fetch the data
  // as soon as it is fetched set the data to the table

  const table = useReactTable({
    data,
    columns,
    // pageCount: Math.ceil(data.length / 10),
    pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    initialState: { pagination: { pageSize: 5 }, globalFilter },
    state: {
      sorting,
      pagination,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
  });

  const getOrganisations = useCallback(async () => {
    setIsLoading(true);
    const response = await apiRequest<Paginated<OrganizationListResponseDto>>(
      `organizations/list?limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}`,
      "GET"
    );

    if (response.data) {
      setData(response.data.docs);
      setPageCount(Math.ceil(response.data.count / pagination.pageSize));
      setItemCount(response.data.count);
    }
    setIsLoading(false);
  }, [apiRequest, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    getOrganisations();
  }, [getOrganisations]);

  return (
    <div className="w-full">
      <Flex className="items-center py-4 gap-4">
        <DropdownMenu>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(!!value)}
                  >
                    {columnNames[column.id.toString()]}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>
      <div className="rounded-md border overflow-hidden">
        {isLoading ? (
          <Flex className="justify-center w-full h-36 items-center bg-white">
            <Spinner size="xl" color="primary.500" />
          </Flex>
        ) : (
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className={`hover:bg-white`}>
                  {headerGroup.headers.map((header) => {
                    // console.log(header.column.columnDef.header);

                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row, i) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={() => {
                      setSelectedOrg(row.original._id);
                    }}
                    className={`hover:bg-slate-100 cursor-pointer`}
                  >
                    {row.getVisibleCells().map((cell, j) => (
                      <TableCell key={cell.id} className={`${j === 0 ? " font-bold" : ""}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    Keine Resultate.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex space-x-2 items-center">
          <span>
            Seite {table.getState().pagination.pageIndex + 1} von {table.getPageCount()}
          </span>
          <Button
            variant="outline"
            size="sm"
            className="text-base"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            zurück
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="text-base"
          >
            weiter
          </Button>
        </div>
      </div>
    </div>
  );
};
