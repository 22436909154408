import { useCallback } from "react";
import { toast } from "sonner";
import { useContext } from "react";
import { Context, ErrorEntity, Error as ErrorI } from "../../components/root/context";

// Interface for options that can be passed to the apiRequest function
interface ApiRequestOptions {
  // Optional toast configuration
  toast?: { toastText?: string };
  // Optional body for the request
  body?: any;
  test?: boolean;
}

// Interface for the response returned by the apiRequest function
interface ApiResponse<T> {
  data: T | undefined;
  error: Error | unknown | undefined;
  status: number;
}

type ApiFileRequestOptions = {
  formdataName: string;
  toast?: {
    toastText?: string;
  };
};


// Custom hook to create an API request function
const useApiRequest = () => {
  const { setError, accessToken } = useContext(Context);
  // useCallback to memoize the apiRequest function
  const apiRequest = useCallback(
    async <T>(
      route: string, // API route
      method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE", // HTTP method
      options?: ApiRequestOptions // Optional request options
    ): Promise<ApiResponse<T>> => {
      // Setting up the fetch options

      const fetchOptions: RequestInit = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${accessToken}`
        },
      };

      // If a body is provided, add it to the fetch options
      if (options?.body) {
        fetchOptions.body = JSON.stringify(options.body);
      }

      // Create a promise for the fetch response
      const responsePromise = new Promise<{ data: T; status: number }>(async (resolve, reject) => {
        try {
          const url = options?.test || process.env.REACT_APP_API?.includes("localhost") ? `${process.env.REACT_APP_API}/v1.0/${route}` : `${process.env.REACT_APP_API}/api/v1.0/${route}`;
          // Fetching data from the API - will be replaced in the future with the api url via env variables
          const res = await fetch(url, fetchOptions);

          //console.log(res);

          // If the response is not ok, reject the promise with an error
          if (!res.ok) {
            const error = await getErrorMessage(res);
            if (setError) {
              setError(error);
            }

            reject({
              error: new Error("Request failed"),
              message: error,
              status: res.status,
            });
            return;
          }

          // Try to parse the response as JSON
          let responseData: T;
          try {
            responseData = await res.json();
          } catch {
            // If parsing fails, reject the promise with an error
            const x = "success" as unknown as T;
            resolve({ data: x, status: res.status });
            return;
          }

          // Resolve the promise with the parsed data
          resolve({
            data: responseData,
            status: res.status,
          });
        } catch (error) {
          // Log the error and reject the promise
          reject({ error, status: 500 });
        }
      });

      // If toast options are provided, display a toast notification
      if (options?.toast) {
        // toast.promise(responsePromise, {
        //   loading: "Loading...", // Message while loading
        //   success: options.toast.toastText || "Success!", // Success message
        //   error: (err) => (err instanceof Error ? err.message : "An error occurred"), // Error message
        // });
        toast.promise(responsePromise, {
          loading: "Loading...", // Message while loading
          success: options.toast.toastText || "Success!", // Success message
          error: (err) => (err instanceof Error ? err.message : "Es ist ein Fehler aufgetreten"), // Error message
        });
      }

      // Wait for the response and handle the result
      try {
        const data = await responsePromise;
        return { data: data.data, error: undefined, status: data.status }; // Return the data if successful
      } catch (errorCatched) {
        const error = errorCatched as unknown as {
          message: string;
          error: Error | unknown;
          status: number;
        };
        return { data: undefined, error: error.message, status: error.status }; // Return the error if failed
      }
    },
    [accessToken, setError]
  );

  const apiFileRequest = useCallback(
    async <T>(
      route: string,
      method: "POST" | "DELETE" | "GET" | "PUT" | "PATCH",
      file: any,
      options?: ApiFileRequestOptions
    ): Promise<ApiResponse<T>> => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      const formdata = new FormData();
      if (options?.formdataName) {
        formdata.append(options.formdataName, file, options.formdataName);
      } else {
        formdata.append(
          Array.isArray(file) ? "files" : "file",
          Array.isArray(file) ? file[0] : file,
          "file"
        );
      }

      const requestOptions: RequestInit = {
        method: method,
        headers: myHeaders,
        body: formdata,
      };

      const responsePromise = new Promise<{ data: T; status: number }>(async (resolve, reject) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API}/api/v1.0/${route}`, requestOptions);

          if (!response.ok) {
            const error = await getErrorMessage(response);
            if (setError) {
              setError(error);
            }
            reject({
              error: new Error("Request failed"),
              message: error,
              status: response.status,
            });
            return;
          }

          let responseData: T;
          try {
            responseData = await response.json();
          } catch {
            reject({ error: new Error("Failed to parse JSON"), message: "Failed to parse JSON", status: response.status });
            return;
          }

          resolve({
            data: responseData,
            status: response.status,
          });
        } catch (error) {
          reject({ error, status: 500 });
        }
      });

      if (options?.toast) {
        toast.promise(responsePromise, {
          loading: "Loading...",
          success: options.toast.toastText || "Success!",
          error: (err) => (err instanceof Error ? err.message : "Es ist ein Fehler aufgetreten"),
        });
      }

      try {
        const data = await responsePromise;
        return { data: data.data, error: undefined, status: data.status };
      } catch (errorCatched) {
        const error = errorCatched as unknown as {
          message: string;
          error: Error | unknown;
          status: number;
        };
        if (error.status > 200 && error.status < 299) {
          toast.success("Bild erfolgreich hochgeladen");
          return { data: undefined, error: undefined, status: error.status };
        }
        toast.error("Es gab leider ein Problem. Bitte versuche es später wieder oder kontaktiere den Support");
        return { data: undefined, error: error.message, status: error.status };
      }
    },
    [accessToken, setError]
  );

  return { apiRequest, apiFileRequest }; // Return the apiRequest function
};

const getErrorMessage = async (res: Response) => {
  let errorMessage = "";
  let errorLocation = "";
  let error: ErrorI;
  let isClosable = true;
  let withReload = false;

  try {
    error = (await res.json()) as ErrorI;
  } catch {
    throw new Error("Failed to parse JSON");
  }

  if (!error) {
    throw new Error("Failed to parse JSON");
  }

  if (res.status === 400) {
    errorMessage = "400 - Scheinbar scheinen Ihre Angaben nicht ganz korrekt zu sein. Bitte korrigiere das und versuche es nochmals.";
  } else if (res.status === 409) {
    errorMessage = "409 - Scheinbar gab es einen Konflikt. Bitte versuche es später nochmals oder kontaktiere den Support.";
  } else if (res.status === 403) {
    errorMessage = "403 - Du scheinst nicht die nötigen Rechte zu haben. Bitte kontaktiere den Support oder Ihren Organisationsadministrator.";
  } else if (res.status === 500) {
    errorMessage = "500 - Es ist ein Fehler aufgetreten, der nicht auftreten soll. Bitte schreibe dem Support. - Zeitpunkt: " + new Date().toLocaleString();
  } else if (res.status === 404) {
    errorMessage = "404 - das Objekt wurde nicht gefunden. Bitte versuche es später nochmals oder kontaktiere den Support";
  } else {
    errorMessage = `${res.status} - Es ist ein Fehler aufgetreten. Bitte versuche es später nochmals oder kontaktiere den Support`;
  }

  if (error.error === "NoUserFoundForbidden") {
    errorMessage = "Der Account wurde erfolgreich erstellt. Um Kandidat.io vollständig nutzen zu können, bitte ergänze hier Ihre Daten.";
    errorLocation = "/register";
    error.entity = ErrorEntity.NoUserFoundForbidden; // TODO redo
    isClosable = false;
  } else if (error.error === ErrorEntity.NoOrganizationForbidden) {
    errorMessage = "Sie sind keiner Organisation angehörig. Bitte erstellen Sie eine Organisation.";
    errorLocation = "/organization/neu";
    error.entity = ErrorEntity.NoOrganizationForbidden;
    isClosable = false;
  } else if (error.error === ErrorEntity.NoSubscriptionForbidden) {
    error.entity = ErrorEntity.NoSubscriptionForbidden;
    isClosable = false;
  } else if (error.entity === ErrorEntity.CompanyLocation) {
    errorMessage = "Sie haben noch keinen keinen Standort für ihr Unternehmen angegeben. Bitte fügen Sie einen Standort hinzu.";
    errorLocation = "/standorte/neu";
    isClosable = false;
  } else if (error.error === ErrorEntity.MissingUserEmailConflict) {
    errorMessage = "Der Bewerber hat keine E-Mail-Adresse. Bitte füge eine E-Mail-Adresse hinzu.";
  } else if (error.error === ErrorEntity.ConflictException) {
    errorMessage = "Diese Mail ist nicht verfügbar. Bitte verwenden Sie eine andere Email.";
  } else if (error.error === ErrorEntity.ForbiddenException) {
    errorMessage = "Bitte kontaktiere den Support";
  } else if (error.error === ErrorEntity.InactiveSubscriptionConflict) {
    error.entity = ErrorEntity.InactiveSubscriptionConflict;
    error.message = "die Zahlung Ihres Abonnement ist zu lange überfällig, oder sie ist deaktiviert. Solange dies der Fall ist, bleibt der Account inaktiv"
    isClosable = false;
  } else if (error.message === "Applicant has no mail address") {
    errorMessage = "Der Bewerber hat keine E-Mail-Adresse. Bitte füge eine E-Mail-Adresse hinzu.";
  } else if (error.error === ErrorEntity.InactiveSubscriptionForbidden) {
    errorMessage = "Die Zahlung Ihres Abonnement ist zu lange überfällig, oder sie ist deaktiviert. Solange dies der Fall ist, bleibt der Account inaktiv";
    error.entity = ErrorEntity.InactiveSubscriptionForbidden;
    isClosable = false;
  } else if (error.error === ErrorEntity.InsufficientSubscriptionForbidden) {
    errorMessage = "Ihr Abonnement ist nicht ausreichend. Bitte kontaktiere den Support.";
    error.entity = ErrorEntity.InsufficientSubscriptionForbidden;
  } else if (error.error === ErrorEntity.Conflict) {
    if (error.message === "Invalid MFA token") {
      errorMessage = "Der Code ist nicht korrekt. Bitte versuche es erneut.";
    } else if (error.message === "No MFA token found") {
      errorMessage = "Es wurde kein Code gefunden. Klicken Sie auf den Button, um einen neuen Code zu erhalten.";
    } else if (error.message === "Sender prefix already taken") {
      errorMessage = "Die eingegebene E-Mail Adresse ist nicht verfügbar."
    } else {
      errorMessage = "Es ist ein Fehler aufgetreten.";
    }
    error.entity = ErrorEntity.Conflict;
  } else if (error.error === ErrorEntity.MFANotAuthorizedForbidden) {
    errorMessage = "MFA ist nicht autorisiert. Bitte kontaktiere den Support.";
    error.entity = ErrorEntity.MFANotAuthorizedForbidden;
  } else if (error.error === ErrorEntity.EMailNotVerifiedForbidden) {
    errorMessage = "Die E-Mail ist nicht verifiziert. Bitte kontaktiere den Support.";
    error.entity = ErrorEntity.EMailNotVerifiedForbidden;
  } else if (error.error === ErrorEntity.InsufficientSubscriptionTooManyUnarchivedJobOffersForbidden) {
    errorMessage = "Ihr aktuelles Abonnement reicht nicht aus für diese Aktion. Wenn das Objekt aus dem Archiv geholt wird, würde die Zahl ihrer Stellenangebote über dem möglichen Kontingent liegen!";
    error.entity = ErrorEntity.InsufficientSubscriptionTooManyUnarchivedJobOffersForbidden;
  } else if (error.message === "No Superadmin Permission") {
    errorMessage = "Diese Funktion ist als Superadmin nicht verfügbar.";
  } else if (error.message === "Token is required") {
    errorMessage = "Diese Funktion ist als Superadmin nicht verfügbar.";
    error.entity = ErrorEntity.NoSuperAdminPermission;
  }

  const parsedError = {
    message: errorMessage,
    error: error.error,
    statusCode: res.status,
    entity: error.entity,
    actionLocation: errorLocation,
    isClosable,
    withReload,
    mail: error.mail,
  };

  return parsedError
}

export default useApiRequest;
