import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiRequest from "../../lib/hooks/useRequest";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../ui/components/ui/dialog";
import { UserInvitationOptionalResponseDto } from "@/lib/interfaces/invitation";

interface InvitationResponse {
  alreadyHasOrganization: boolean;
  organizationName: string;
}

const Invitation = ({
  isInviteOpen,
  setInviteOpen,
  uuid,
}: {
  isInviteOpen?: boolean;
  setInviteOpen?: Dispatch<SetStateAction<boolean>>;
  uuid?: string;
}) => {
  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();
  const [inviteRes, setInviteRes] = useState<InvitationResponse>();
  const { isAuthenticated, isLoading } = useAuth0();
  const [invitation, setInvitation] = useState<UserInvitationOptionalResponseDto>();

  const getInvitation = useCallback(async () => {
    const res = await apiRequest<UserInvitationOptionalResponseDto>("organizations/invitations", "GET");
    if (res.data) {
      setInvitation(res.data);
    }
  }, [apiRequest]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate("/register");
      return;
    }
    getInvitation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInvitation]);

  const checkInvite = useCallback(async () => {
    if (invitation?.invitation && !window.location.pathname.includes("register")) {
      setInviteRes(invitation.invitation);
    }
  }, [invitation]);

  useEffect(() => {
    if (invitation?.invitation) {
      if (!isLoading && isAuthenticated) {
        checkInvite();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInvite, navigate, invitation]);

  const handleInvite = async (action: "accept" | "decline") => {
    if (invitation?.invitation) {
      const inviteCode = invitation.invitation._id;
      await apiRequest(`organizations/invitations/${inviteCode}/${action}`, "PATCH", {
        toast: {
          toastText: "Einladung wurde angenommen",
        },
      });
      window.location.reload();
    }
  };

  if (!setInviteOpen) {
    return <></>;
  }

  return (
    <>
      {inviteRes && (
        <Dialog open={isInviteOpen} onOpenChange={(value) => setInviteOpen(value)}>
          <DialogContent className="z-50" isClosable={false}>
            <DialogHeader>
              <DialogTitle>Einladung</DialogTitle>
            </DialogHeader>
            <div>
              <p>
                {inviteRes.alreadyHasOrganization
                  ? `Du hast bereits eine Organisation. Willst du deine aktuelle verlassen um ${inviteRes.organizationName} beizutreten?`
                  : `Du wurdest eingeladen ${inviteRes.organizationName} beizutreten.`}
                <br />
                <span className="italic">Man kann nur einer Organisation angehören.</span>
              </p>
            </div>
            <DialogFooter>
              <div className={`flex gap-3 w-full`}>
                <Button colorScheme="blue" className="grow" onClick={() => handleInvite("accept")}>
                  Einladung annehmen
                </Button>
                <Button colorScheme="blue" className="grow" variant="outline" onClick={() => handleInvite("decline")}>
                  Einladung ablehnen
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export { Invitation };
