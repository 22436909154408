import { Box, Button, Flex, Image, Spinner } from "@chakra-ui/react";
import { CheckIcon, CopyIcon, Pencil } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../ui/components/ui/dialog";
import useApiRequest from "../../lib/hooks/useRequest";

enum ThirdPartyAppsEnum {
  ZAPIER = "zapier",
  MAKE = "make",
  PERSPECTIVE = "perspective",
  HEYFLOW = "heyflow",
  MEETOVO = "meetovo",
  INDEED = "indeed",
  KLEINANZEIGEN = "kleinanzeigen",
}

const ThirdPartyApps = () => {
  const [currentApp, setCurrentApp] = useState<ThirdPartyAppsEnum>();

  return (
    <Flex className="p-8 rounded border border-gray-300 h-full flex-col gap-12 bg-white">
      <Flex className="gap-5" flexWrap="wrap">
        <Flex className="flex-col items-center justify-center h-64 w-80 rounded-lg border border-gray-300">
          <Box className="max-h-36 h-full flex items-center">
            <Image alt="zapier" src="../zapier-logo.png" />
          </Box>
          <Button
            colorScheme="primary"
            className="flex gap-2 py-2 px-4"
            variant="solid"
            onClick={() => setCurrentApp(ThirdPartyAppsEnum.ZAPIER)}
          >
            <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
              <Pencil />
            </Box>
            <div>Einstellen</div>
          </Button>
        </Flex>
        <Flex className="flex-col items-center justify-center h-64 w-80 rounded-lg border border-gray-300">
          <Box className="max-h-36 h-full flex items-center">
            <Image alt="make" src="../make-logo.png" />
          </Box>
          <Button
            colorScheme="primary"
            className="flex gap-2 py-2 px-4"
            variant="solid"
            onClick={() => setCurrentApp(ThirdPartyAppsEnum.MAKE)}
          >
            <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
              <Pencil />
            </Box>
            <div>Einstellen</div>
          </Button>
        </Flex>
        <Flex className="flex-col items-center justify-center h-64 w-80 rounded-lg border border-gray-300">
          <Box className="max-h-36 h-full flex items-center">
            <Image alt="perspective" src="../perspective-logo.png" />
          </Box>
          <Button colorScheme="primary" className="flex gap-2 py-2 px-4" variant="outline" isDisabled={true}>
            Coming soon...
          </Button>
        </Flex>
        <Flex className="flex-col items-center justify-center h-64 w-80 rounded-lg border border-gray-300">
          <Box className="max-h-36 h-full flex items-center">
            <Image alt="heyflow" src="../heyflow-logo.png" />
          </Box>
          <Button colorScheme="primary" className="flex gap-2 py-2 px-4" variant="outline" isDisabled={true}>
            Coming soon...
          </Button>
        </Flex>
        <Flex className="flex-col items-center justify-center h-64 w-80 rounded-lg border border-gray-300">
          <Box className="max-h-36 h-full flex items-center">
            <Image alt="meetovo" src="../meetovo-logo.png" />
          </Box>
          <Button colorScheme="primary" className="flex gap-2 py-2 px-4" variant="outline" isDisabled={true}>
            Coming soon...
          </Button>
        </Flex>
        <Flex className="flex-col items-center justify-center h-64 w-80 rounded-lg border border-gray-300">
          <Box className="max-h-36 h-full flex items-center">
            <Image alt="indeed" src="../indeed-logo.png" />
          </Box>
          <Button colorScheme="primary" className="flex gap-2 py-2 px-4" variant="outline" isDisabled={true}>
            Coming soon...
          </Button>
        </Flex>
        <Flex className="flex-col items-center justify-center h-64 w-80 rounded-lg border border-gray-300">
          <Box className="max-h-36 h-full flex items-center">
            <Image alt="Kleinanzeigen" src="../kleinanzeigen-logo.png" />
          </Box>
          <Button colorScheme="primary" className="flex gap-2 py-2 px-4" variant="outline" isDisabled={true}>
            Coming soon...
          </Button>
        </Flex>
      </Flex>
      <Dialog open={currentApp ? true : false} onOpenChange={() => setCurrentApp(undefined)}>
        {currentApp === ThirdPartyAppsEnum.MAKE && <APIDialog route="make" title="Make" />}
        {currentApp === ThirdPartyAppsEnum.ZAPIER && <APIDialog route="zapier" title="Zapier" />}
      </Dialog>
    </Flex>
  );
};

const APIDialog = ({ route, title }: { route: string; title: string }) => {
  const [apiKey, setApiKey] = useState<string>();
  const { apiRequest } = useApiRequest();

  const getApiKey = useCallback(async () => {
    const response = await apiRequest<{ apiKey: string }>(`organizations/apiKeys/${route}`, "GET");
    if (response.data && response.data.apiKey) {
      setApiKey(response.data.apiKey);
    } else {
      setApiKey("");
    }
  }, [apiRequest, route]);

  const createApiKey = async () => {
    const response = await apiRequest<{ apiKey: string }>(`organizations/apiKeys/${route}`, "POST");
    if (response.data) {
      setApiKey(response.data.apiKey);
    }
  };

  const deleteApiKey = async () => {
    await apiRequest(`organizations/apiKeys/${route}`, "DELETE");
    setApiKey("");
  };

  useEffect(() => {
    getApiKey();
  }, [getApiKey]);

  return (
    <DialogContent className="overflow-hidden">
      <DialogHeader>
        <DialogTitle className="text-center">{title} einrichten</DialogTitle>
      </DialogHeader>
      {apiKey !== undefined ? (
        <div>
          <Flex className="flex-col gap-5">
            <Flex className="flex-col gap-2">
              {apiKey !== "" && <ApiKeyField apiKey={apiKey} />}
              <Buttons onGenerate={createApiKey} onDelete={deleteApiKey} apiKey={apiKey} />
            </Flex>
          </Flex>
        </div>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </DialogContent>
  );
};

const Buttons = ({
  onGenerate,
  onDelete,
  apiKey,
}: {
  onGenerate: () => void;
  onDelete: () => void;
  apiKey: string;
}) => {
  return (
    <div className="w-full flex gap-3">
      <div className={apiKey !== "" ? "w-1/2" : "grow"}>
        <Button colorScheme="primary" onClick={onGenerate} className="w-full">
          {apiKey !== "" ? "API Schlüssel generieren" : "Neuen API Schlüssel generieren"}
        </Button>
        {apiKey !== "" && (
          <div className="text-xs text-gray-500 mt-2">
            <p>Wenn du einen neuen API Schlüssel generierst, wird der alte Schlüssel deaktiviert.</p>
          </div>
        )}
      </div>
      {apiKey !== "" && (
        <Button colorScheme="red" onClick={onDelete} className="grow" variant="outline">
          API Schlüssel deaktivieren
        </Button>
      )}
    </div>
  );
};

const ApiKeyField = ({ apiKey }: { apiKey: string }) => {
  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    if (hasCopied) {
      const timeout = setTimeout(() => {
        setHasCopied(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [hasCopied]);

  return (
    <div className="gap-2 w-full">
      <div>API Key:</div>
      <div className="flex justify-between p-3 bg-slate-100 rounded-lg border">
        <div className="">
          <code>{apiKey}</code>
        </div>
        {hasCopied ? (
          <CheckIcon />
        ) : (
          <CopyIcon
            onClick={() => {
              navigator.clipboard.writeText(apiKey);
              setHasCopied(true);
            }}
            className="cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default ThirdPartyApps;
