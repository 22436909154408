import { Box, Button, Flex, Heading } from "@chakra-ui/react";

import { SmallAddIcon } from "@chakra-ui/icons";
import { DataTable } from "./DataTable";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useUser } from "../../hooks/use-user";
import NoPermissionRead from "../../ui/components/ui/NoPermission";

export const LocationsParent = () => {
  const { user } = useUser();
  if (!user) return null;
  return (
    <>
      {user.permissionCompanyLocations === "NONE" && !user.permissionOrganizationAdmin ? (
        <NoPermissionRead title="Standorte" />
      ) : (
        <Locations />
      )}
    </>
  );
};

const Locations = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [limitiations, setLimitations] = useState<{
    allowedElementsCount: number;
    usedElementsCount: number;
  }>();

  if (!user) return null;

  return (
    <Flex direction="column" padding="1rem 1.5rem">
      <Flex width="100%" justifyContent="space-between" margin="0 0 1.5em">
        <Heading size="lg">Standorte</Heading>
        <Flex gap="1rem">
          <Button
            colorScheme="primary"
            color="white"
            className="flex gap-2 p-2"
            variant="solid"
            isDisabled={
              (limitiations &&
                limitiations?.allowedElementsCount <= limitiations?.usedElementsCount &&
                limitiations?.allowedElementsCount !== -1) ||
              (user.permissionCompanyLocations !== "FULL" && !user.permissionOrganizationAdmin)
            }
            onClick={() => {
              navigate("/standorte/neu");
            }}
          >
            <Box
              className="text-primary"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              bg="white"
              boxSize="1rem"
            >
              <SmallAddIcon />
            </Box>
            <span>Standort hinzufügen</span>
          </Button>
        </Flex>
      </Flex>
      <DataTable limitiations={limitiations} setLimitations={setLimitations} />
    </Flex>
  );
};

export { Locations };
