import { convertFromHTML, ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

export const convertHtmlToEditorState = (html: string) => {
  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  return EditorState.createWithContent(contentState);
};

export const convertHtmlToContentState = (html: string) => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    return ContentState.createFromBlockArray(contentBlock.contentBlocks);
  }
  return ContentState.createFromText('');
};