import { OrganizationResponseDto } from "@/lib/interfaces/organizations";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Save } from "lucide-react";
import { useState } from "react";
import { Editor, EditorState } from "react-draft-wysiwyg";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import { convertHtmlToEditorState } from "../../lib/utils/convertHtml";
import { Form, FormField, FormItem, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";

const PrivacyPolicy = ({ organization }: { organization: OrganizationResponseDto }) => {
  const defaultContent = organization.privacyPolicyTemplate ?? ""; // Your default content as HTML
  const [editorNewState, setEditorNewState] = useState(() => convertHtmlToEditorState(defaultContent));
  const { apiRequest } = useApiRequest();

  const formSchema = z.object({
    privacyPolicyTemplate: z.string().min(1, "Bitte fügen Sie eine Datenschutzerklärung ein"),
    imprintLink: z.union([
      z.literal(""),
      z.string().trim().url({
        message: "Bitte geben Sie eine gültige URL ein",
      }),
    ]),
    privacyPolicyLink: z.union([
      z.literal(""),
      z.string().trim().url({
        message: "Bitte geben Sie eine gültige URL ein",
      }),
    ]),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      privacyPolicyTemplate: organization.privacyPolicyTemplate ?? "",
      imprintLink: organization.imprintLink ?? "",
      privacyPolicyLink: organization.privacyPolicyLink ?? "",
    },
  });

  type FormValues = z.infer<typeof formSchema>;

  async function onSubmit(values: FormValues) {
    const contentState = editorNewState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    // Convert raw content to plain text
    const htmlContent = draftToHtml(rawContentState);

    const req = {
      privacyPolicyTemplate: htmlContent,
      imprintLink: values.imprintLink === "" ? null : values.imprintLink,
      privacyPolicyLink: values.privacyPolicyLink === "" ? null : values.privacyPolicyLink,
    };

    await apiRequest(`organizations/`, "PATCH", {
      body: req,
      toast: { toastText: "Daten erfolgreich aktualisiert" },
    });
  }

  const onEditorNewStateChange = (newState: EditorState) => {
    setEditorNewState(newState);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex gap-8 h-full bg-white">
        <Flex className="p-8 rounded border border-gray-300 gap-8 flex-col h-full bg-white">
          <div>
            <Flex className="gap-8 mb-6">
              <Box className="w-2/5">
                <Heading size="sm" className="font-bold mb-4">
                  Ihre Datenschutzerklärung
                </Heading>
                <Text>
                  Die Datenschutzerklärung erläutert den Umgang mit personenbezogenen Daten im Bewerbungsverfahren und
                  informiert über Rechte der Bewerber gemäß Datenschutzgesetzen.
                </Text>
              </Box>
              <Box className="w-3/5">
                <Editor
                  editorState={editorNewState}
                  wrapperClassName="editorWrapper"
                  editorClassName="editorContent"
                  onEditorStateChange={onEditorNewStateChange}
                />
              </Box>
            </Flex>

            <FormField
              control={form.control}
              name="imprintLink"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="w-2/5 font-bold mt-4 text-md">Impressum</FormLabel>
                  <Box className="w-3/5">
                    <FormControl>
                      <Input placeholder="Impressum" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="privacyPolicyLink"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="w-2/5 font-bold mt-4 text-md">Datenschutzerklärung</FormLabel>
                  <Box className="w-3/5">
                    <FormControl>
                      <Input placeholder="Datenschutzerklärung" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
          </div>
          <Flex className="gap-4 justify-end mt-auto">
            <Button
              colorScheme="primary"
              color="white"
              className="flex gap-2 p-2 text-white"
              variant="solid"
              type="submit"
            >
              <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                <Save />
              </Box>
              <span>Speichern</span>
            </Button>
          </Flex>
        </Flex>
      </form>
    </Form>
  );
};

export default PrivacyPolicy;
