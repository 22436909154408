import { Heading } from "@chakra-ui/react";
import React from "react";

const Help = () => {
  return (
    <div className="h-full">
      <div className="w-1/2 m-auto h-full flex flex-col justify-center">
        <Heading className="mb-60">
          Unser Hilfe-Portal ist aktuell noch im Aufbau und steht Ihnen in Kürze zur Verfügung
        </Heading>
      </div>
    </div>
  );
};

export { Help };
