import React from "react";

const Test = () => {
  if (true) {
    throw new Error("Test error");
  }
  return <div>Test hello</div>;
};

export { Test };
