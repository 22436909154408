import { MailSignatureDetailsResponseDto } from "@/lib/interfaces/organizations";
import { Box, Button, Flex, Switch, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import ImageUpload from "../../lib/utils/image-upload";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";

const EmailSignature = () => {
  const { apiRequest } = useApiRequest();

  const [mailSignature, setMailSignature] = useState<MailSignatureDetailsResponseDto>();
  const [isActivated, setIsActivated] = useState(false);

  const getMailSignature = useCallback(async () => {
    const response = await apiRequest<MailSignatureDetailsResponseDto>("organizations/mail-signature", "GET");

    if (response?.data) {
      setMailSignature(response.data);
      setIsActivated(response.data.mailSignatureActive);
    } else {
      setMailSignature(undefined);
    }
  }, [apiRequest]);

  useEffect(() => {
    // get organization details from API
    getMailSignature();
  }, [apiRequest, getMailSignature]);

  const toggleActive = async (active: boolean) => {
    await apiRequest(`organizations/mail-signature/${active ? "activate" : "deactivate"}`, "PATCH", {
      toast: { toastText: active ? "Email Signatur aktiviert" : "Email Signatur deaktiviert" },
    });
    setIsActivated(active);
  };

  return (
    <Flex className="p-8 rounded border border-gray-300 h-full flex-col gap-12 bg-white">
      <Switch
        sx={{ "span.chakra-switch__track:not([data-checked])": { backgroundColor: "gray.400" } }}
        isChecked={isActivated}
        onChange={() => toggleActive(!isActivated)}
        display="flex"
        alignItems="center"
        className="w-fit"
      >
        Email Signatur aktivieren
      </Switch>
      {mailSignature && isActivated && <EmailSignatureForm mailSignature={mailSignature} />}
    </Flex>
  );
};

const EmailSignatureForm = ({ mailSignature }: { mailSignature: MailSignatureDetailsResponseDto }) => {
  const { apiRequest } = useApiRequest();
  const [twoSecondsNoInput, setTwoSecondsNoInput] = useState(false);
  const [gotAnInput, setGotAnInput] = useState(false);
  const mailSignatureContainer = useRef<HTMLDivElement>(null);
  const [isValid, setIsValid] = useState(true);

  const formSchema = z.object({
    mailSignatureTemplate: z.string().optional(),
    facebookUrl: z.union([
      z.literal(""),
      z.string().trim().url({
        message: "Bitte geben Sie eine gültige URL ein",
      }),
    ]),
    instagramUrl: z.union([
      z.literal(""),
      z.string().trim().url({
        message: "Bitte geben Sie eine gültige URL ein",
      }),
    ]),
    linkedInUrl: z.union([
      z.literal(""),
      z.string().trim().url({
        message: "Bitte geben Sie eine gültige URL ein",
      }),
    ]),
    xingUrl: z.union([
      z.literal(""),
      z.string().trim().url({
        message: "Bitte geben Sie eine gültige URL ein",
      }),
    ]),
    youtubeUrl: z.union([
      z.literal(""),
      z.string().trim().url({
        message: "Bitte geben Sie eine gültige URL ein",
      }),
    ]),
    recruitingVideoUrl: z.union([
      z.literal(""),
      z.string().trim().url({
        message: "Bitte geben Sie eine gültige URL ein",
      }),
    ]),
    recruitingVideoThumbnailUrl: z.union([
      z.literal(""),
      z.string().trim().url({
        message: "Bitte geben Sie eine gültige URL ein",
      }),
    ]),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      mailSignatureTemplate: mailSignature.mailSignatureTemplate ?? "",
      facebookUrl: mailSignature.facebookUrl ?? "",
      instagramUrl: mailSignature.instagramUrl ?? "",
      linkedInUrl: mailSignature.linkedInUrl ?? "",
      xingUrl: mailSignature.xingUrl ?? "",
      youtubeUrl: mailSignature.youtubeUrl ?? "",
      recruitingVideoUrl: mailSignature.recruitingVideoUrl ?? "",
      recruitingVideoThumbnailUrl: mailSignature.recruitingVideoThumbnailUrl ?? "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const req = {
      mailSignatureActive: mailSignature.mailSignatureActive,
      facebookUrl: values.facebookUrl === "" ? undefined : values.facebookUrl,
      instagramUrl: values.instagramUrl === "" ? undefined : values.instagramUrl,
      linkedInUrl: values.linkedInUrl === "" ? undefined : values.linkedInUrl,
      xingUrl: values.xingUrl === "" ? undefined : values.xingUrl,
      youtubeUrl: values.youtubeUrl === "" ? undefined : values.youtubeUrl,
      recruitingVideoUrl: values.recruitingVideoUrl === "" ? undefined : values.recruitingVideoUrl,
    };

    await apiRequest(`organizations/mail-signature`, "PATCH", {
      body: req,
      toast: { toastText: "Daten erfolgreich aktualisiert" },
    });
  }

  const getLive = useCallback(async () => {
    let queryStr = "";
    if (form.getValues("facebookUrl")) {
      queryStr += `facebookUrl=${form.getValues("facebookUrl")}&`;
    }
    if (form.getValues("instagramUrl")) {
      queryStr += `instagramUrl=${form.getValues("instagramUrl")}&`;
    }
    if (form.getValues("linkedInUrl")) {
      queryStr += `linkedInUrl=${form.getValues("linkedInUrl")}&`;
    }
    if (form.getValues("xingUrl")) {
      queryStr += `xingUrl=${form.getValues("xingUrl")}&`;
    }
    if (form.getValues("youtubeUrl")) {
      queryStr += `youtubeUrl=${form.getValues("youtubeUrl")}&`;
    }
    if (form.getValues("recruitingVideoUrl")) {
      queryStr += `recruitingVideoUrl=${form.getValues("recruitingVideoUrl")}&`;
    }

    const response = await apiRequest<{
      mailSignatureTemplate: string;
    }>(`organizations/mail-signature/live?${queryStr}`, "GET");

    if (response?.data) {
      if (mailSignatureContainer.current) {
        mailSignatureContainer.current.innerHTML = response.data.mailSignatureTemplate;
      }
    }
  }, [apiRequest, form]);

  const check = useCallback(async () => {
    const valid = await form.trigger();
    setIsValid(valid);
    if (valid && twoSecondsNoInput) {
      console.log("hey there");
      getLive();
    }
  }, [form, getLive, twoSecondsNoInput]);

  useEffect(() => {
    check();
  }, [check, twoSecondsNoInput]);

  useEffect(() => {
    if (mailSignatureContainer.current && mailSignature.mailSignatureTemplate) {
      mailSignatureContainer.current.innerHTML = mailSignature.mailSignatureTemplate;
    }
  }, [mailSignature.mailSignatureTemplate]);

  useEffect(() => {
    console.log("siuu");
    const timer = setTimeout(() => {
      setTwoSecondsNoInput(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, [gotAnInput]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex gap-8 h-full bg-white">
        <Box className="flex flex-col gap-16 w-1/2">
          <Flex className="gap-16 h-full">
            <Box className="w-full">
              <FormField
                control={form.control}
                name="facebookUrl"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">Facebook Seite</FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input
                          placeholder="Facebook"
                          {...field}
                          onChange={(value) => {
                            setTwoSecondsNoInput(false);
                            setGotAnInput((prev) => !prev);
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="instagramUrl"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">Instagram Profil</FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input
                          placeholder="Instagram"
                          {...field}
                          onChange={(value) => {
                            setTwoSecondsNoInput(false);
                            setGotAnInput((prev) => !prev);
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="linkedInUrl"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">LinkedIn Profil</FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input
                          placeholder="LinkedIn"
                          {...field}
                          onChange={(value) => {
                            setTwoSecondsNoInput(false);
                            setGotAnInput((prev) => !prev);
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="xingUrl"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">Xing Profil</FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input
                          placeholder="Xing"
                          {...field}
                          onChange={(value) => {
                            setTwoSecondsNoInput(false);
                            setGotAnInput((prev) => !prev);
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="youtubeUrl"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-1 text-md">YouTube Kanal</FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input
                          placeholder="YouTube"
                          {...field}
                          onChange={(value) => {
                            setTwoSecondsNoInput(false);
                            setGotAnInput((prev) => !prev);
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="recruitingVideoThumbnailUrl"
                render={({ field }) => (
                  <FormItem className="flex gap-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">
                      Vorschaubild für Video <Text className="text-gray-400">(empfohlen)</Text>
                    </FormLabel>
                    <ImageUpload
                      route="organizations/mail-signature/image"
                      baseImg={mailSignature.recruitingVideoThumbnailUrl ?? ""}
                      size="small"
                      imageLabel="Bild"
                      onUpload={() => {
                        setTwoSecondsNoInput(false);
                        setGotAnInput((prev) => !prev);
                      }}
                    />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="recruitingVideoUrl"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">Link zum Recruitingfilm</FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input
                          placeholder="Recruitingfilm"
                          {...field}
                          onChange={(value) => {
                            setTwoSecondsNoInput(false);
                            setGotAnInput((prev) => !prev);
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
            </Box>
          </Flex>
        </Box>
        <Box className="w-1/2">
          <Box className="h-full flex flex-col">
            <div className="w-full h-full p-4 border rounded-xl">
              {isValid && <div ref={mailSignatureContainer}></div>}
            </div>
            <Box className="flex flex-col justify-between">
              <Flex className="gap-4 justify-end mt-auto">
                <Button
                  colorScheme="primary"
                  color="white"
                  className="flex gap-2 p-2 text-white ml-auto mt-4"
                  variant="solid"
                  type="submit"
                >
                  <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                    <Save />
                  </Box>
                  <span>Speichern</span>
                </Button>
              </Flex>
            </Box>
          </Box>
        </Box>
      </form>
    </Form>
  );
};

export default EmailSignature;
