import { Heading } from "@chakra-ui/react";
import React from "react";

const NoPermissionRead = ({ title }: { title: string }) => {
  return (
    <div className="w-fit m-auto h-full flex flex-col justify-center">
      <Heading className="text-center">Kein Zugriff</Heading>
      <p className="text-center">Sie haben keine Berechtigung, auf die {title} zuzugreifen.</p>
    </div>
  );
};

export default NoPermissionRead;
