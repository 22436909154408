import { SmallAddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { DataAccordion } from "./DataAccordion";

import { useCallback, useEffect, useState } from "react";
import useApiRequest from "../../lib/hooks/useRequest";
import { JobOfferListWithCompanyLocationsPaginatedResponseDto } from "@/lib/interfaces/job-offer";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/use-user";
import NoPermissionRead from "../../ui/components/ui/NoPermission";

export const JobOffersParent = () => {
  const { user } = useUser();
  if (!user) return null;
  return (
    <>
      {user.permissionJobOffers === "NONE" && !user.permissionOrganizationAdmin ? (
        <NoPermissionRead title="Stellenangebote" />
      ) : (
        <JobOffers />
      )}
    </>
  );
};

const JobOffers = () => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [response, setResponse] = useState<JobOfferListWithCompanyLocationsPaginatedResponseDto>();
  const [limitiations, setLimitations] = useState<{
    allowedElementsCount: number;
    usedElementsCount: number;
  }>();
  const { user } = useUser();
  const { apiRequest } = useApiRequest();
  const [reload, setReload] = useState(false);

  const getOffers = useCallback(async () => {
    const response = await apiRequest<JobOfferListWithCompanyLocationsPaginatedResponseDto>("job-offers", "GET");

    if (response.data) {
      setResponse(response.data);
      if (response.data.jobOffers.allowedElementsCount && response.data.jobOffers.usedElementsCount) {
        setLimitations({
          allowedElementsCount: response.data.jobOffers.allowedElementsCount,
          usedElementsCount: response.data.jobOffers.usedElementsCount,
        });
      } else {
        setLimitations({
          allowedElementsCount: response.data.jobOffers.allowedElementsCount ?? -1,
          usedElementsCount: response.data.jobOffers.usedElementsCount ?? 0,
        });
      }
    }
  }, [apiRequest]);

  useEffect(() => {
    getOffers();
  }, [getOffers, reload]);

  const navigate = useNavigate();

  if (!user) return null;

  return (
    <Flex direction="column" padding="1rem 1.5rem">
      <Flex width="100%" justifyContent="space-between" margin="0 0 1.5em">
        <Heading size="lg">Stellenangebote</Heading>
        <Flex gap="1rem" className="flex-col lg:flex-row">
          <Button
            colorScheme="primary"
            className="flex gap-2 p-2 text-white"
            variant="solid"
            onClick={() => {
              navigate(`/stellenangebote/neu`);
            }}
            isDisabled={
              (limitiations &&
                limitiations.allowedElementsCount !== -1 &&
                limitiations?.allowedElementsCount <= limitiations?.usedElementsCount) ||
              (user.permissionCompanyLocations !== "FULL" &&
                user.permissionCompanyLocations !== "WRITE" &&
                !user.permissionOrganizationAdmin)
            }
          >
            <Box
              className="text-primary"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              bg="white"
              boxSize="1rem"
            >
              <SmallAddIcon />
            </Box>
            <span>Stellenangebot erstellen</span>
          </Button>
          {/* <Button
            leftIcon={<DownloadIcon size="18" />}
            colorScheme="primary"
            variant="outline"
            padding="0.5rem"
            onClick={() => {
              toast.promise(
                new Promise<void>((resolve) => {
                  setTimeout(() => {
                    resolve();
                  }, 2000);
                }),
                {
                  loading: "Downloading...",
                  success: "Downloaded!",
                  error: "Error!",
                }
              );
            }}
          >
            Stellenangebote einbetten
          </Button> */}
        </Flex>
      </Flex>
      <Flex className="items-center py-4 gap-4"></Flex>
      {response && (
        <DataAccordion
          data={response}
          // onSwitchStatus={handleStatusToggle}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          limitiations={limitiations}
          setLimitations={setLimitations}
          setReload={setReload}
        />
      )}
      {limitiations && (
        <Flex
          className={`${
            limitiations.allowedElementsCount === -1
              ? "bg-green-100 text-green-700"
              : limitiations.allowedElementsCount - limitiations.usedElementsCount === 1
              ? "bg-orange-100 text-orange-700"
              : limitiations.allowedElementsCount <= limitiations.usedElementsCount
              ? "bg-red-100 text-red-700"
              : "bg-green-100 text-green-700"
          } rounded-md w-fit p-2`}
        >
          <Text fontSize="sm">
            {limitiations.usedElementsCount} von{" "}
            {limitiations.allowedElementsCount === -1 ? "∞" : limitiations.allowedElementsCount} Stellenangeboten
            genutzt
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export { JobOffers };
