// import useApiRequest from "../../lib/hooks/useRequest";
import { JobOfferListWithCompanyLocationsPaginatedResponseDto } from "@/lib/interfaces/job-offer";
// import { Paginated } from "@/lib/interfaces/utils";
import { Flex, Heading } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../ui/components/ui/accordion";
import { DataTable } from "./DataTable";
import { Text } from "@chakra-ui/react";

interface AccordionProps {
  data: JobOfferListWithCompanyLocationsPaginatedResponseDto;
  // onSwitchStatus: (id: string, newStatus: boolean) => void;
  globalFilter: string;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
  setReload: Dispatch<SetStateAction<boolean>>;
  limitiations:
    | {
        allowedElementsCount: number;
        usedElementsCount: number;
      }
    | undefined;
  setLimitations: Dispatch<
    SetStateAction<
      | {
          allowedElementsCount: number;
          usedElementsCount: number;
        }
      | undefined
    >
  >;
}

// export const DataAccordion: React.FC<AccordionProps> = ({ data, onSwitchStatus, globalFilter, setGlobalFilter }) => {
export const DataAccordion: React.FC<AccordionProps> = ({ data, globalFilter, setGlobalFilter, setReload }) => {
  const [currentLocation, setCurrentLocation] = useState(data.foundCompanyLocationId);
  // const [loading, setLoading] = useState(false);
  // const { apiRequest } = useApiRequest();

  const onOpen = (e: string) => {
    if (e === currentLocation) return;
    else if (e === "") return;
    setCurrentLocation(e);
  };

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full"
      defaultValue={data.companyLocations[0]._id}
      onValueChange={(e) => onOpen(e)}
    >
      {data.companyLocations.map((location) => {
        return (
          <AccordionItem key={location._id} value={location._id}>
            <AccordionTrigger>
              <Flex className="items-baseline gap-3">
                <Heading size="md">{location.value}</Heading>
                <Text className="text-md" color="var(--chakra-colors-gray-500)">
                  {location.jobOfferCount} Stellenausschreibungen gesamt | {location.activeJobOfferCount} aktiv
                </Text>
              </Flex>
            </AccordionTrigger>
            <AccordionContent>
              {location._id === currentLocation && (
                <DataTable
                  locationId={currentLocation}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  firstData={currentLocation === data.foundCompanyLocationId ? data.jobOffers : undefined}
                  setReload={setReload}
                />
              )}
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
