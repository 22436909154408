import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useEffect, useState } from "react";

import { OrganizationUsersArrayResponseDto, OrganizationUsersResponseDto } from "@/lib/interfaces/organizations";
import { Button, Flex, Text } from "@chakra-ui/react";
import useApiRequest from "../../lib/hooks/useRequest";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/components/ui/table";
import { useNavigate } from "react-router-dom";

export function UserDataTable() {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<OrganizationUsersResponseDto[]>([]);
  const { apiRequest } = useApiRequest();
  const [limitations, setLimitations] = useState<{
    allowedElementsCount: number;
    usedElementsCount: number;
  }>();

  const columns: ColumnDef<OrganizationUsersResponseDto>[] = [
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "mail",
      header: "Email",
    },
    {
      accessorKey: "role",
      header: "Rolle",
      cell: ({ row }) => {
        const isPending = row.original.name === "Noch ausstehend...";
        const revokeInvitation = async () => {
          const response = await apiRequest(`organizations/invitations/${row.original.invitationId}/revoke`, "PATCH");
          if (response.data) {
            getData();
          }
        };

        return (
          <div className="flex w-full justify-between">
            <p className="self-center">{row.original.permissionOrganizationAdmin ? "Admin" : "Nutzer"}</p>
            {isPending && (
              <Button
                borderColor="error"
                textColor="error"
                className="flex gap-2"
                justifyContent="flex-start"
                variant="outline"
                height="fit-content"
                onClick={revokeInvitation}
                padding="4px"
              >
                <span>Einladung löschen</span>
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    pageCount: Math.ceil(data.length / 10),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    initialState: { pagination: { pageSize: 10 }, globalFilter },
    state: {
      sorting,
      globalFilter,
    },
  });
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    const response = await apiRequest<OrganizationUsersArrayResponseDto>("organizations/users", "GET");
    if (response.data) {
      setData(response.data.users);
      setLimitations({
        allowedElementsCount: response.data.allowedElementsCount,
        usedElementsCount: response.data.usedElementsCount,
      });
    }
  }, [apiRequest]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="w-full">
      <div className="rounded-md border overflow-hidden">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, i) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className={`hover:bg-slate-100 ${
                    row.original.permissionOrganizationAdmin || !row.original._id ? "" : "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (row.original.permissionOrganizationAdmin) return;
                    if (!row.original._id) return;
                    navigate(`/nutzer/bearbeiten/${row.original._id}`);
                  }}
                >
                  {row.getVisibleCells().map((cell, j) => (
                    <TableCell key={cell.id} className={`${j === 0 ? " font-bold" : ""}`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  Keine Resultate.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex space-x-2 items-center">
          <span>
            Seite {table.getState().pagination.pageIndex + 1} von {table.getPageCount()}
          </span>
          <Button
            variant="outline"
            size="sm"
            className="text-base"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            zurück
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="text-base"
          >
            weiter
          </Button>
        </div>
      </div>
      {limitations && (
        <Flex
          className={`${
            limitations.allowedElementsCount === -1
              ? "bg-green-100 text-green-700"
              : limitations.allowedElementsCount - limitations.usedElementsCount === 1
              ? "bg-orange-100 text-orange-700"
              : limitations.allowedElementsCount <= limitations.usedElementsCount
              ? "bg-red-100 text-red-700"
              : "bg-green-100 text-green-700"
          } rounded-md w-fit p-2`}
        >
          <Text fontSize="sm">
            {limitations.usedElementsCount} von{" "}
            {limitations.allowedElementsCount === -1 ? "∞" : limitations.allowedElementsCount} Benutzern eingeladen
          </Text>
        </Flex>
      )}
    </div>
  );
}
