const Placeholder = ({
  placeholders,
  onPlaceholderClicked,
}: {
  placeholders: string[];
  onPlaceholderClicked: (stringToAdd: string) => void;
}) => {
  return (
    <div className="mb-3">
      <div className="mb-1">
        <p>Platzhalter einfügen:</p>
      </div>
      <div className="flex gap-2 flex-wrap">
        {placeholders.map((placeholder) => {
          return (
            <div
              key={placeholder}
              onClick={() => {
                onPlaceholderClicked(placeholder);
              }}
              className="cursor-pointer bg-gray-100 hover:bg-gray-200 py-0 px-2 rounded-md w-fit"
            >
              {placeholder}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Placeholder;
