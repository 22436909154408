import { CompanyLocationSelectableEntityResponseDto } from "./job-offer";

export enum ValutationEnum {
  NONE = "Nicht bewertet",
  BAD = "Schlecht",
  NEUTRAL = "Mittel",
  GOOD = "Gut",
}

export enum ApplicantLogEvent {
  APPLICANT_CREATED = "Der Bewerber wurde erstellt",
  APPLICANT_DATA_UPDATED = "Der Bewerber wurde bearbeitet",
  APPLICANT_DELETED = "Der Bewerber wurde gelöscht",
  APPLICANT_ANONYMIZED = "Der Bewerber wurde Anonymisiert",
  APPLICANT_STATUS_CHANGED = "Status geändert",
  APPLICANT_MAIL_SENT = "Dem Bewerber wurde eine E-Mail versendet",
}

export enum Genders {
  "NONE" = "Kein Geschlecht ausgewählt",
  "MALE" = "Herr",
  "FEMALE" = "Frau",
  "DIVERS" = "Divers",
}

export interface CreateApplicantRequestDto {
  name: string;
  gender?: string;
  street?: string;
  postCode?: string;
  placeName?: string;
  experience?: string;
  schoolQualification?: string;
  professionalTraining?: string;
  phone?: string;
  mail?: string | null;
  contactAvailability?: string;
  notes?: string;
  belongsToCompanyLocationId?: string;
  belongsToJobOfferId?: string;
  valuation?: ValutationEnum;
  belongsToSource: string;
  belongsToStatus: string;
  applicationDate: string;
  todo: string;
}

export interface ApplicantResponseDto {
  belongsToOrganizationId: string;
  createdAt: string;
  updatedAt: string;
  _id: string;
  name: string;
  gender: string;
  street: string;
  postCode: string;
  placeName: string;
  experience: string;
  schoolQualification: string;
  professionalTraining: string;
  phone: string;
  mail: string;
  contactAvailability: string;
  notes: string;
  belongsToCompanyLocationId: string;
  belongsToJobOfferId: string;
  companyLocationTitle: string;
  jobOfferTitle: string;
  lastEditedFrom: string;
  valuation: ValutationEnum;
  belongsToSource: string;
  belongsToStatus: string;
  applicationDate: string;
}

export interface ApplicantListResponseDto {
  _id: string;
  name: string;
  gender: string;
  placeName: string;
  experience: string;
  companyLocationTitle: string;
  jobOfferTitle: string;
  valuation: ValutationEnum;
  belongsToStatus: string;
  applicationDate: string;
  belongsToSource: string;
  todo?: string;
}

export interface JobOfferSelectableEntityResponseDto {
  _id: string;
  value: string;
  isArchive: boolean;
}

export interface ApplicantStatusSelectableEntityResponseDto {
  id: string;
  value: string;
  default: boolean;
  color: string;
}

export interface ApplicantSourceSelectableEntityResponseDto {
  id: string;
  value: string;
}

export interface ApplicantCreationEntitiesResponseDto {
  companyLocations: CompanyLocationSelectableEntityResponseDto[];
  foundCompanyLocationId: string;
  jobOffers: JobOfferSelectableEntityResponseDto[];
  status: ApplicantStatusSelectableEntityResponseDto[];
  sources: ApplicantSourceSelectableEntityResponseDto[];
  genders: Genders[];
}

export interface ApplicantCreationEntitiesByCompanyLocationIdResponseDto {
  jobOffers: JobOfferSelectableEntityResponseDto[];
}

export interface ApplicantListEntitiesResponseDto {
  status: ApplicantStatusSelectableEntityResponseDto[];
  sources: ApplicantSourceSelectableEntityResponseDto[];
}

export interface ApplicantSelectableEntitiesResponseDto {
  companyLocations: CompanyLocationSelectableEntityResponseDto[];
  foundCompanyLocationId: string;
  jobOffers: JobOfferSelectableEntityResponseDto[];
  status: ApplicantStatusSelectableEntityResponseDto[];
  sources: CompanyLocationSelectableEntityResponseDto[];
}

export interface ApplicantDetailsResponseDto {
  _id: string;
  name: string;
  gender: string;
  street: string;
  postCode: string;
  placeName: string;
  experience: string;
  schoolQualification: string;
  professionalTraining: string;
  phone: string;
  mail: string;
  contactAvailability: string;
  notes: string;
  belongsToCompanyLocationId: string;
  belongsToJobOfferId: string;
  lastEditedFrom: string;
  valuation: ValutationEnum;
  belongsToSource: string;
  sourceTitle: string;
  belongsToStatus: string;
  applicationDate: string;
  applicantLogs: ApplicantLog[];
  selectableEntities: ApplicantCreationEntitiesResponseDto;
  todo: string;
  isEditable: boolean;
  companyLocationTitle: string;
  jobOfferTitle: string;
}

export interface ApplicantLog {
  createdAt: string;
  event: ApplicantLogEvent;
  information?: string;
}

export interface UpdateApplicantRequestDto {
  name: string;
  gender: string;
  street: string;
  postCode: string;
  placeName: string;
  experience: string;
  schoolQualification: string;
  professionalTraining: string;
  phone: string;
  mail: string;
  contactAvailability: string;
  notes: string;
  belongsToCompanyLocationId: string;
  belongsToJobOfferId: string;
  valuation: ValutationEnum;
  belongsToSource: string;
  belongsToStatus: string;
  applicationDate: string;
}

export interface ApplicantMailTemplateWithValuesResponseDto {
  _id: string;
  title: string;
  template: string;
  subject: string;
  receiver: string;
  placeholders: ApplicantMailTemplatePlaceholderValueResponseDto[];
  from?: {
    name: string;
    addressPrefix: string;
  }
  replyTo?: {
    name: string;
    address: string;
  }
}

export interface ApplicantMailTemplatePlaceholderValueResponseDto {
  placeholder: string;
  value: string;
}

export interface ApplicantMailTemplatesResponseDto {
  labels: MailTemplateLabelSelectableListWithTemplatesResponseDto[];
}

export interface MailTemplateLabelSelectableListWithTemplatesResponseDto {
  _id: string;
  value: string;
  mailTemplates: ApplicantMailTemplateListResponseDto[];
}

export interface ApplicantMailTemplateListResponseDto {
  _id: string;
  value: string;
}

