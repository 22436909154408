import { Button } from "@chakra-ui/react";
import useApiRequest from "../../lib/hooks/useRequest";
import { useSearchParams } from "react-router-dom";

const MailVerification = () => {
  const { apiRequest } = useApiRequest();
  let [searchParams] = useSearchParams();
  const mail = searchParams.get("mail");

  const resendMail = async () => {
    await apiRequest("users/resend-email-verification", "POST", {
      toast: {
        toastText: "Email wurde erneut gesendet",
      },
    });
  };

  return (
    <div
      className="w-full h-full"
      style={{
        background: `url("/login-bg.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-20 w-5/12">
          <div className="text-center flex gap-6 items-center justify-center">
            <img src="/logo.svg" alt="" className="w-[75px]" />
            <h2 className="text-white font-semibold text-4xl">kandidat.io</h2>
          </div>
          <div className="bg-white p-8 rounded-lg flex flex-col gap-4">
            <h3 className="text-2xl font-semibold text-center">Email-Bestätigung</h3>
            <h4 className="text-lg text-center">Eine Email wurde an {mail ?? "Ihre Mail"} gesendet</h4>
            <p className="text-center text-gray-600">
              Um Zugriff auf alle Funktionen zu erhalten, bestätigen Sie bitte Ihre Email-Adresse. Überprüfen Sie Ihren
              Posteingang und klicken Sie auf den Bestätigungslink. Schauen Sie auch in Ihrem Spam-Ordner nach.
            </p>
            <div>
              <Button colorScheme="primary" color="white" className="w-full" onClick={resendMail}>
                Email erneut senden
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MailVerification };
