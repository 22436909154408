import { Box, Button } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";

const Organization = () => {
  const { apiRequest } = useApiRequest();

  const formSchema = z.object({
    name: z.string().min(2, "Füge deinen Firmennamen hinzu"),
    street: z.string().min(2, "Füge deine Strasse hinzu"),
    postCode: z.string().min(2, "Füge deine Postleitzahl hinzu"),
    placeName: z.string().min(2, "Füge deinen Ort hinzu"),
    ceo: z.string().min(2, "Füge deinen Geschäftsführer hinzu"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      street: "",
      postCode: "",
      placeName: "",
      ceo: "",
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const req = {
      name: data.name,
      street: data.street,
      postCode: data.postCode,
      placeName: data.placeName,
      ceo: data.ceo,
    };
    const res = await apiRequest("organizations", "POST", {
      body: req,
      toast: {
        toastText: "Organisation erstellt",
      },
    });
    if (res.status < 300 && res.status >= 200) {
      window.location.href = "/";
    }
  };

  return (
    <div
      className="w-full h-full"
      style={{
        background: `url("/login-bg.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-20 w-5/12">
          <div className="text-center flex gap-6 items-center justify-center">
            <img src="/logo.svg" alt="" className="w-[75px]" />
            <h2 className="text-white font-semibold text-4xl">kandidat.io</h2>
          </div>
          <Form {...form}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                form.handleSubmit(onSubmit)();
              }}
              className="bg-white p-8 rounded-lg flex flex-col gap-4"
            >
              <h3 className="text-2xl font-semibold text-center">Bitte gib deine Firmendaten ein</h3>
              <div className="form-inputs">
                <div className="row">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className="gap-4 mb-4 w-full">
                        <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                          Firmenname<span className="text-primary">*</span>
                        </FormLabel>
                        <Box className="w-full">
                          <FormControl>
                            <Input placeholder="Firmenname" {...field} />
                          </FormControl>
                          <FormMessage />
                        </Box>
                      </FormItem>
                    )}
                  />
                </div>
                <div className="row flex gap-4">
                  <FormField
                    control={form.control}
                    name="street"
                    render={({ field }) => (
                      <FormItem className="gap-4 mb-4 w-full">
                        <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                          Strasse<span className="text-primary">*</span>
                        </FormLabel>
                        <Box className="w-full">
                          <FormControl>
                            <Input placeholder="Strasse" {...field} />
                          </FormControl>
                          <FormMessage />
                        </Box>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="postCode"
                    render={({ field }) => (
                      <FormItem className="gap-4 mb-4 w-full">
                        <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                          Postzeitzahl<span className="text-primary">*</span>
                        </FormLabel>
                        <Box className="w-full">
                          <FormControl>
                            <Input placeholder="Postleizahl" {...field} />
                          </FormControl>
                          <FormMessage />
                        </Box>
                      </FormItem>
                    )}
                  />
                </div>
                <div className="row flex gap-4">
                  <FormField
                    control={form.control}
                    name="placeName"
                    render={({ field }) => (
                      <FormItem className="gap-4 mb-4 w-full">
                        <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                          Ort<span className="text-primary">*</span>
                        </FormLabel>
                        <Box className="w-full">
                          <FormControl>
                            <Input placeholder="Ort" {...field} />
                          </FormControl>
                          <FormMessage />
                        </Box>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="ceo"
                    render={({ field }) => (
                      <FormItem className="gap-4 mb-4 w-full">
                        <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                          Geschäftsführer<span className="text-primary">*</span>
                        </FormLabel>
                        <Box className="w-full">
                          <FormControl>
                            <Input placeholder="Max" {...field} />
                          </FormControl>
                          <FormMessage />
                        </Box>
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex flex-col mt-4">
                  <Button
                    colorScheme="primary"
                    color="white"
                    className="flex gap-2 p-2 text-white self-end"
                    variant="solid"
                    type="submit"
                  >
                    <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                      <Save />
                    </Box>
                    <span>Speichern</span>
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export { Organization };
