import { CompanyLocationSelectableEntityResponseDto } from "./statistics";

export enum PermissionOption {
  NONE = "Keine",
  READ = "Sichtrecht",
  WRITE = "Bearbeiten",
  FULL = "Vollzugriff",
}

export interface OrganizationResponseDto {
  name: string;
  street: string;
  postCode: string;
  placeName: string;
  ceo: string;
  agencyEmail?: string;
  employeeBenefitsTemplate: string;
  imagePath?: string;
  imageUrl?: string;
  privacyPolicyTemplate?: string;
  internalMailTemplateTitle: string;
  internalMailTemplateDescription: string;
  internalMailTemplateSubject: string;
  internalMailTemplateTemplate: string;
  mailTemplatePlaceholders: string[];
  ciColorPrimary: string;
  privacyPolicyLink: string;
  imprintLink: string;
}

export interface UpdateOrganizationRequestDto {
  name: string;
  street: string;
  postCode: string;
  placeName: string;
  ceo: string;
  agencyEmail?: string;
  employeeBenefitsTemplate: string;
  imagePath?: string;
  imageUrl?: string;
  ciColorPrimary: string;
}

export interface MailSignatureDetailsResponseDto {
  mailSignatureActive: boolean;
  mailSignatureTemplate?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  linkedInUrl?: string;
  xingUrl?: string;
  youtubeUrl?: string;
  recruitingVideoUrl?: string;
  recruitingVideoThumbnailUrl?: string;
}

export interface OrganizationUsersResponseDto {
  _id: string;
  name?: string;
  mail: string;
  permissionOrganizationAdmin: boolean;
  isInvited: boolean;
  invitationId?: string;
}

export interface OrganizationInvitationCreationEntitiesResponseDto {
  companyLocations: CompanyLocationSelectableEntityResponseDto[];
  permissionEditable: boolean;
}

export interface OrganizationUserPermissionResponseDto {
  _id: string;
  isEditable: string;
  permissionMailTemplates: PermissionOption;
  permissionCompanyLocations: PermissionOption;
  permissionJobOffers: PermissionOption;
  permissionApplicants: PermissionOption;
  permittedCompanyLocations: string[];
  permittedAllCompanyLocations: boolean;
  permittedContentWithoutCompanyLocationAssignment: boolean;
  selectableEntities: OrganizationInvitationCreationEntitiesResponseDto;
}

export interface OrganizationUsersArrayResponseDto {
  users: OrganizationUsersResponseDto[];
  allowedElementsCount: number;
  usedElementsCount: number;
}

export interface InviteUserRequestDto {
  email: string;
  permissions: Permissions;
}

export interface Permissions {
  permissionMailTemplates: string;
  permissionCompanyLocations: string;
  permissionJobOffers: string;
  permissionApplicants: string;
  permittedCompanyLocations: string[];
  permittedAllCompanyLocations: boolean;
  permittedContentWithoutCompanyLocationAssignment: boolean;
}

export interface OrganizationListResponseDto {
  _id: string;
  name: string;
  ceo: string;
  subscriptionId: string;
  subscriptionStatus: string;
}