import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save, X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import {
  InviteUserRequestDto,
  OrganizationInvitationCreationEntitiesResponseDto,
  OrganizationUserPermissionResponseDto,
  PermissionOption,
  Permissions,
} from "../../lib/interfaces/organizations";
import { Form, FormField, FormItem, FormMessage } from "../../ui/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/components/ui/select";
import { useUser } from "../../hooks/use-user";
import { Checkbox } from "../../ui/components/ui/checkbox";

const UserEditor = () => {
  const navigate = useNavigate();
  const { apiRequest } = useApiRequest();
  const [item, setItem] = useState<OrganizationUserPermissionResponseDto>();
  const [creationEntities, setCreationEntities] = useState<OrganizationInvitationCreationEntitiesResponseDto>();
  const [permittedAnyCompanyLocations, setPermittedAnyCompanyLocations] = useState(true);
  const [locationsUpdated, setLocationsUpdated] = useState(false);
  const { id } = useParams();
  const { user } = useUser();

  const getItem = useCallback(async () => {
    const response = await apiRequest<OrganizationUserPermissionResponseDto>(`organizations/users/${id}`, "GET");

    if (response.data) {
      setItem(response.data);
      setCreationEntities(response.data.selectableEntities);
    }
  }, [apiRequest, id]);

  const getEntities = useCallback(async () => {
    const creationEntitiesResponse = await apiRequest<OrganizationInvitationCreationEntitiesResponseDto>(
      "organizations/invitations/creation-entities",
      "GET"
    );
    if (creationEntitiesResponse.data) {
      setCreationEntities(creationEntitiesResponse.data);
    }
  }, [apiRequest]);

  useEffect(() => {
    if (id) {
      getItem();
    } else {
      getEntities();
    }
  }, [getEntities, getItem, id]);

  const formSchema = z.object({
    email: item ? z.string().optional() : z.string().trim().email("Bitte geben Sie eine E-Mail Adresse ein"),
    permissionApplicants: z.nativeEnum(PermissionOption),
    permissionCompanyLocations: z.nativeEnum(PermissionOption),
    permissionJobOffers: z.nativeEnum(PermissionOption),
    permissionMailTemplates: z.nativeEnum(PermissionOption),
    permittedContentWithoutCompanyLocationAssignment: z.boolean(),
    permittedAllCompanyLocations: z.boolean(),
    permittedCompanyLocations: z.array(z.string()),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      permissionApplicants: item?.permissionApplicants ?? PermissionOption.FULL,
      permissionCompanyLocations: item?.permissionCompanyLocations ?? PermissionOption.WRITE,
      permissionJobOffers: item?.permissionJobOffers ?? PermissionOption.WRITE,
      permissionMailTemplates: item?.permissionMailTemplates ?? PermissionOption.WRITE,
      permittedAllCompanyLocations: item?.permittedAllCompanyLocations ?? true,
      permittedContentWithoutCompanyLocationAssignment: item?.permittedContentWithoutCompanyLocationAssignment ?? true,
      permittedCompanyLocations: item?.permittedCompanyLocations ?? [],
    },
  });

  const setItemValues = useCallback(() => {
    if (!item) return;
    form.setValue(
      "permissionApplicants",
      PermissionOption[item.permissionApplicants as unknown as keyof typeof PermissionOption]
    );
    form.setValue(
      "permissionCompanyLocations",
      PermissionOption[item.permissionCompanyLocations as unknown as keyof typeof PermissionOption]
    );
    form.setValue(
      "permissionJobOffers",
      PermissionOption[item.permissionJobOffers as unknown as keyof typeof PermissionOption]
    );
    form.setValue(
      "permissionMailTemplates",
      PermissionOption[item.permissionMailTemplates as unknown as keyof typeof PermissionOption]
    );
    form.setValue("permittedAllCompanyLocations", item.permittedAllCompanyLocations);
    form.setValue("permittedCompanyLocations", item.permittedCompanyLocations);
    form.setValue(
      "permittedContentWithoutCompanyLocationAssignment",
      item.permittedContentWithoutCompanyLocationAssignment
    );
  }, [form, item]);

  useEffect(() => {
    if (item) {
      setItemValues();
    }
  }, [form, item, setItemValues]);

  const parsePermissions = (permission: PermissionOption) => {
    return (Object.keys(PermissionOption) as Array<keyof typeof PermissionOption>).find(
      (key) => PermissionOption[key] === permission
    );
  };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    if (!data.email) {
      const req: Permissions = {
        permissionApplicants: parsePermissions(data.permissionApplicants) ?? "FULL",
        permissionCompanyLocations: parsePermissions(data.permissionCompanyLocations) ?? "WRITE",
        permissionJobOffers: parsePermissions(data.permissionJobOffers) ?? "WRITE",
        permissionMailTemplates: parsePermissions(data.permissionMailTemplates) ?? "WRITE",
        permittedAllCompanyLocations: data.permittedAllCompanyLocations,
        permittedCompanyLocations: data.permittedCompanyLocations,
        permittedContentWithoutCompanyLocationAssignment: data.permittedContentWithoutCompanyLocationAssignment,
      };
      const res = await apiRequest(`organizations/users/${item?._id}`, "PATCH", {
        body: req,
        toast: {
          toastText: "Nutzerberechtigungen aktualisiert",
        },
      });
      if (res.status < 300 && res.status >= 200) {
        navigate("/einstellungen");
      }
    } else {
      const req: InviteUserRequestDto = {
        email: data.email,
        permissions: {
          permissionApplicants: parsePermissions(data.permissionApplicants) ?? "FULL",
          permissionCompanyLocations: parsePermissions(data.permissionCompanyLocations) ?? "WRITE",
          permissionJobOffers: parsePermissions(data.permissionJobOffers) ?? "WRITE",
          permissionMailTemplates: parsePermissions(data.permissionMailTemplates) ?? "WRITE",
          permittedAllCompanyLocations: data.permittedAllCompanyLocations,
          permittedCompanyLocations: data.permittedCompanyLocations,
          permittedContentWithoutCompanyLocationAssignment: data.permittedContentWithoutCompanyLocationAssignment,
        },
      };
      const res = await apiRequest("organizations/invitations", "POST", {
        body: req,
        toast: {
          toastText: "Der Nutzer wurde per Mail benachrichtigt.",
        },
      });
      if (res.status < 300 && res.status >= 200) {
        navigate("/einstellungen");
      }
    }
  };

  useEffect(() => {
    const withoutLocation = form.watch("permittedContentWithoutCompanyLocationAssignment");
    const isTrue = form.watch("permittedAllCompanyLocations") || form.watch("permittedCompanyLocations").length > 0;
    if (isTrue) {
      setPermittedAnyCompanyLocations(true);
      if (item) {
        form.setValue(
          "permissionCompanyLocations",
          PermissionOption[item.permissionCompanyLocations as unknown as keyof typeof PermissionOption]
        );
        form.setValue(
          "permissionJobOffers",
          PermissionOption[item.permissionJobOffers as unknown as keyof typeof PermissionOption]
        );
      } else {
        form.setValue("permissionCompanyLocations", PermissionOption.WRITE);
        form.setValue("permissionJobOffers", PermissionOption.WRITE);
      }
    } else {
      setPermittedAnyCompanyLocations(false);
      form.setValue("permissionJobOffers", PermissionOption.NONE);
      form.setValue("permissionCompanyLocations", PermissionOption.NONE);
    }
    if (withoutLocation === false && !isTrue) {
      form.setValue("permissionApplicants", PermissionOption.NONE);
    } else {
      if (item) {
        form.setValue(
          "permissionApplicants",
          PermissionOption[item.permissionApplicants as unknown as keyof typeof PermissionOption]
        );
      } else {
        form.setValue("permissionApplicants", PermissionOption.FULL);
      }
    }
  }, [form, item, locationsUpdated]);

  return (
    <Flex direction="column" padding="1rem 1.5rem" className="h-full">
      <Flex width="100%" justifyContent="space-between" margin="0 0 1.5em">
        <Heading size="lg">
          <Text as="span" className="font-normal">
            Einstellungen
          </Text>{" "}
          &gt;{" "}
          <Text as="span" className="font-semibold">
            {item ? "Nutzer bearbeiten" : "Neuer Nutzer"}
          </Text>
        </Heading>
      </Flex>
      <Flex className="p-8 rounded border border-gray-300 gap-24 h-full bg-white w-full">
        <Form {...form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit(onSubmit, (e) => {
                console.log(e);
              })();
            }}
            className="bg-white p-8 rounded-lg flex-col flex gap-4 w-full justify-between"
          >
            <div className="form-inputs flex gap-8">
              {!item && (
                <div className="row grow">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="gap-4 mb-4 w-full">
                        <FormLabel className="w-full font-bold mt-3 text-md">
                          Email<span className="text-primary">*</span>
                        </FormLabel>
                        <Box className="w-full">
                          <FormControl>
                            <Input placeholder="Email" {...field} />
                          </FormControl>
                          <FormMessage />
                        </Box>
                      </FormItem>
                    )}
                  />
                </div>
              )}
              <div className="row grow w-1/2">
                <Heading size="md" className="font-bold">
                  Standortberechtigungen
                </Heading>
                <FormField
                  control={form.control}
                  name="permittedContentWithoutCompanyLocationAssignment"
                  render={({ field }) => (
                    <FormItem className="flex gap-4 mb-4 w-full">
                      <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                        Bewerber ohne Standortzuweisung
                      </FormLabel>
                      <Box className="w-full flex">
                        <FormControl className="self-center">
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={(checked) => {
                              setLocationsUpdated((prev) => !prev);
                              field.onChange(checked);
                            }}
                            disabled={user?.allowedFunctionRoleManagement === false}
                          />
                        </FormControl>
                        <FormMessage />
                      </Box>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="permittedAllCompanyLocations"
                  render={({ field }) => (
                    <FormItem className="flex gap-4 mb-4 w-full">
                      <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                        Alle Standorte erlauben
                      </FormLabel>
                      <Box className="w-full flex">
                        <FormControl className="self-center">
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={(checked) => {
                              setLocationsUpdated((prev) => !prev);
                              field.onChange(checked);
                            }}
                            disabled={user?.allowedFunctionRoleManagement === false}
                          />
                        </FormControl>
                        <FormMessage />
                      </Box>
                    </FormItem>
                  )}
                />
                {form.watch("permittedAllCompanyLocations") === false && (
                  <FormField
                    control={form.control}
                    name="permittedCompanyLocations"
                    render={({ field }) => (
                      <FormItem className="flex gap-4 mb-4 w-full">
                        <FormLabel className="max-w-[186px] w-full font-bold mt-4">Verfügbare Standorte</FormLabel>
                        <Box className="w-full">
                          <FormControl>
                            <div className="flex flex-col gap-2">
                              {creationEntities?.companyLocations.map((location) => (
                                <div className="flex gap-2" key={location._id}>
                                  <Checkbox
                                    id={location._id}
                                    name={location._id}
                                    checked={field.value?.includes(location._id)}
                                    onCheckedChange={(checked) => {
                                      setLocationsUpdated((prev) => !prev);
                                      return checked
                                        ? field.onChange([...field.value, location._id])
                                        : field.onChange(field.value?.filter((value) => value !== location._id));
                                    }}
                                    disabled={item ? !item.isEditable : false}
                                  />
                                  <div className="grid gap-1.5 leading-none">
                                    <label
                                      htmlFor={location._id}
                                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                      {location.value}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </FormControl>
                          <FormMessage />
                        </Box>
                      </FormItem>
                    )}
                  />
                )}
                <Heading size="md" className="font-bold">
                  Berechtigungen
                </Heading>
                <FormField
                  control={form.control}
                  name="permissionApplicants"
                  render={({ field }) => {
                    console.log(field.value);
                    return (
                      <FormItem className="flex gap-4 mb-4 w-full">
                        <FormLabel className="max-w-[100px] w-full font-bold mt-3 text-md">Bewerber</FormLabel>
                        <Box className="w-full">
                          <FormControl>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                              }}
                              value={field.value} // Use the enum key as the value
                              disabled={
                                user?.allowedFunctionRoleManagement === false ||
                                (!permittedAnyCompanyLocations &&
                                  form.getValues("permittedContentWithoutCompanyLocationAssignment") === false)
                              }
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue>
                                    {field.value}
                                    {field.value === PermissionOption.FULL && " (Löschen & Anonymisieren)"}
                                    {field.value === PermissionOption.WRITE && " (Erstellen & Mails versenden)"}
                                  </SelectValue>
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {Object.keys(PermissionOption).map((permissionKey) => (
                                  <>
                                    <SelectItem
                                      key={permissionKey}
                                      value={PermissionOption[permissionKey as keyof typeof PermissionOption]}
                                    >
                                      {PermissionOption[permissionKey as keyof typeof PermissionOption]}
                                      {permissionKey === "FULL" && " (Löschen & Anonymisieren)"}
                                      {permissionKey === "WRITE" && " (Erstellen & Mails versenden)"}
                                    </SelectItem>
                                  </>
                                ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </Box>
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="permissionCompanyLocations"
                  render={({ field }) => (
                    <FormItem className="flex gap-4 mb-4 w-full">
                      <FormLabel className="max-w-[100px] w-full font-bold mt-3 text-md">Standorte</FormLabel>
                      <Box className="w-full">
                        <FormControl>
                          <Select
                            onValueChange={(value) => {
                              field.onChange(value);
                            }}
                            disabled={user?.allowedFunctionRoleManagement === false || !permittedAnyCompanyLocations}
                            value={field.value} // Use the enum key as the value
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue>
                                  {field.value}
                                  {field.value === PermissionOption.FULL && " (Erstellen & Archivieren)"}
                                </SelectValue>
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {/* Map over enum keys and display their labels */}
                              {Object.keys(PermissionOption).map((permissionKey) => (
                                <>
                                  <SelectItem
                                    key={permissionKey}
                                    value={PermissionOption[permissionKey as keyof typeof PermissionOption]}
                                  >
                                    {PermissionOption[permissionKey as keyof typeof PermissionOption]}
                                    {permissionKey === "FULL" && " (Erstellen & Archivieren)"}
                                  </SelectItem>
                                </>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </Box>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="permissionJobOffers"
                  render={({ field }) => (
                    <FormItem className="flex gap-4 mb-4 w-full">
                      <FormLabel className="max-w-[100px] w-full font-bold mt-3 text-md">Jobangebote</FormLabel>
                      <Box className="w-full">
                        <FormControl>
                          <Select
                            onValueChange={(value) => {
                              field.onChange(value);
                            }}
                            disabled={user?.allowedFunctionRoleManagement === false || !permittedAnyCompanyLocations}
                            value={field.value} // Use the enum key as the value
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue>
                                  {field.value}
                                  {field.value === PermissionOption.FULL && " (Archivieren)"}
                                  {field.value === PermissionOption.WRITE && " (und erstellen)"}
                                </SelectValue>
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {/* Map over enum keys and display their labels */}
                              {Object.keys(PermissionOption).map((permissionKey) => (
                                <>
                                  <SelectItem
                                    key={permissionKey}
                                    value={PermissionOption[permissionKey as keyof typeof PermissionOption]}
                                  >
                                    {PermissionOption[permissionKey as keyof typeof PermissionOption]}
                                    {permissionKey === "FULL" && " (Archivieren)"}
                                    {permissionKey === "WRITE" && " (und erstellen)"}
                                  </SelectItem>
                                </>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </Box>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="permissionMailTemplates"
                  render={({ field }) => (
                    <FormItem className="flex gap-4 mb-4 w-full">
                      <FormLabel className="max-w-[100px] w-full font-bold mt-3 text-md">Mailvorlagen</FormLabel>
                      <Box className="w-full">
                        <FormControl>
                          <Select
                            onValueChange={(value) => {
                              field.onChange(value);
                            }}
                            disabled={user?.allowedFunctionRoleManagement === false}
                            value={field.value} // Use the enum key as the value
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue>{field.value}</SelectValue>
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {Object.keys(PermissionOption).map((permissionKey) => (
                                <>
                                  {permissionKey !== "FULL" && (
                                    <SelectItem
                                      key={permissionKey}
                                      value={PermissionOption[permissionKey as keyof typeof PermissionOption]}
                                    >
                                      {PermissionOption[permissionKey as keyof typeof PermissionOption]}
                                    </SelectItem>
                                  )}
                                </>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </Box>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <Flex className="gap-4 justify-end mt-auto">
                <Button
                  colorScheme="primary"
                  color="white"
                  className="flex gap-2 p-2 text-white self-end"
                  variant="solid"
                  type="submit"
                >
                  <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                    <Save />
                  </Box>
                  <span>{item ? "Speichern" : "Einladen"}</span>
                </Button>
                <Button
                  colorScheme="primary"
                  className="flex gap-2 p-2 text-white"
                  variant="outline"
                  onClick={() => {
                    navigate("/einstellungen");
                  }}
                >
                  <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                    <X />
                  </Box>
                  <span>Schließen</span>
                </Button>
              </Flex>
            </div>
          </form>
        </Form>
      </Flex>
    </Flex>
  );
};

export default UserEditor;
