import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import ApplicantEditor from "./components/applicants/ApplicantEditor";
import PositionEditor from "./components/job-offers/PositionEditor";
import LocationEditor from "./components/locations/LocationEditor";
import UserEditor from "./components/settings/UserEditor";
import { useUser } from "./hooks/use-user";
import Applicants from "./pages/Applicants";
import EmailDispatch from "./pages/EmailDispatch";
import Help from "./pages/Help";
import Home from "./pages/Home";
import Invitation from "./pages/Invitation";
import JobOffers from "./pages/JobOffers";
import Locations from "./pages/Locations";
import Organization from "./pages/Organization";
import PageNotFound from "./pages/PageNotFound";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import Settings from "./pages/Settings";
import Test from "./pages/Test";
import UserSettings from "./pages/UserSettings";
import NoAccess from "./pages/NoAccess";
import TwoFAVerification from "./pages/TwoFAVerification";
import MailVerification from "./pages/MailVerification";
import SuperAdmin from "./pages/SuperAdmin";

const Routes = () => {
  const [element, setElement] = useState(<Spinner />);
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [isProfileComplete, setProfileComplete] = useState(false);
  const { user: loggedInUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      setElement(<Spinner />);
      return;
    }
    if (!isAuthenticated) {
      return;
    }
    // if (!loggedInUser.hasOrganization) {
    //   navigate("/organization/neu");
    //   return;
    // }
    // if (!loggedInUser.hasSetupDone) {
    //   navigate("/register");
    //   return;
    // }

    // if (!user?.email_verified) {
    //   setElement(<>approve email</>);
    //   return;
    // }
    setProfileComplete(true);
  }, [isAuthenticated, isLoading, navigate, user]);

  const getElement = (route: string, element: JSX.Element, title: string) => {
    if (!loggedInUser) {
      return <Spinner />;
    }
    if (!loggedInUser.showBackToSuperadmin && loggedInUser.showSuperadminPanel && !route.includes("/super-admin")) {
      return <NoAccess title={title} redirectLocation="/super-admin" />;
    }
    if (route.includes("/super-admin") && loggedInUser.showSuperadminPanel && !loggedInUser.showBackToSuperadmin) {
      return element;
    } else if (
      route.includes("/super-admin") &&
      !loggedInUser.showSuperadminPanel &&
      loggedInUser.showBackToSuperadmin
    ) {
      return <NoAccess title={title} redirectLocation="/" />;
    } else if (
      route === "/" &&
      (loggedInUser.permissionOrganizationAdmin ||
        ((loggedInUser.permittedAnyCompanyLocations || loggedInUser.permittedContentWithoutCompanyLocationAssignment) &&
          loggedInUser.permissionApplicants !== "NONE"))
    ) {
      return element;
    } else if (
      route.includes("/bewerber") &&
      (loggedInUser.permissionOrganizationAdmin ||
        ((loggedInUser.permittedAnyCompanyLocations || loggedInUser.permittedContentWithoutCompanyLocationAssignment) &&
          loggedInUser.permissionApplicants !== "NONE"))
    ) {
      return element;
    } else if (
      route.includes("/stellenangebote") &&
      (loggedInUser.permissionOrganizationAdmin ||
        (loggedInUser.permittedAnyCompanyLocations && loggedInUser.permissionJobOffers !== "NONE"))
    ) {
      return element;
    } else if (
      route.includes("/standorte") &&
      (loggedInUser.permissionOrganizationAdmin ||
        (loggedInUser.permittedAnyCompanyLocations && loggedInUser.permissionCompanyLocations !== "NONE"))
    ) {
      return element;
    } else if (
      route.includes("/email-vorlagen") &&
      loggedInUser?.allowedFunctionMailAutomation &&
      (loggedInUser?.permissionMailTemplates !== "NONE" || loggedInUser?.permissionOrganizationAdmin)
    ) {
      return element;
    } else if (
      (route.includes("/einstellungen") || route.includes("/nutzer/neu") || route.includes("/nutzer/bearbeiten/:id")) &&
      loggedInUser?.permissionOrganizationAdmin
    ) {
      return element;
    }
    return <NoAccess title={title} />;
  };

  const routes = useRoutes([
    { path: "/", element: isProfileComplete ? getElement("/", <Home />, "die Startseite") : element },
    { path: "/test", element: isProfileComplete ? <Test /> : element },
    { path: "/verification/2fa", element: <TwoFAVerification /> },
    { path: "/verification/mail", element: <MailVerification /> },
    {
      path: "/stellenangebote",
      element: isProfileComplete ? getElement("/stellenangebote", <JobOffers />, "die Stellenangebote") : element,
    },
    {
      path: "/stellenangebote/neu",
      element: isProfileComplete
        ? getElement("/stellenangebote/neu", <PositionEditor />, "die Stellenangebote")
        : element,
    },
    {
      path: "/stellenangebote/:id",
      element: isProfileComplete
        ? getElement("/stellenangebote/:id", <PositionEditor />, "die Stellenangebote")
        : element,
    },
    {
      path: "/bewerber",
      element: isProfileComplete ? getElement("/bewerber", <Applicants />, "die Bewerber") : element,
    },
    {
      path: "/bewerber/neu",
      element: isProfileComplete ? getElement("/bewerber/neu", <ApplicantEditor />, "die Bewerber") : element,
    },
    {
      path: "/bewerber/:id",
      element: isProfileComplete ? getElement("/bewerber/:id", <ApplicantEditor />, "die Bewerber") : element,
    },
    { path: "/register", element: <Register /> },
    {
      path: "/standorte",
      element: isProfileComplete ? getElement("/standorte", <Locations />, "die Standorte") : element,
    },
    {
      path: "/standorte/neu",
      element: isProfileComplete ? getElement("/standorte", <LocationEditor />, "die Standorte") : element,
    },
    {
      path: "/standorte/:id",
      element: isProfileComplete ? getElement("/standorte", <LocationEditor />, "die Standorte") : element,
    },
    {
      path: "/email-vorlagen",
      element: isProfileComplete ? getElement("/email-vorlagen", <EmailDispatch />, "die Mailautomatisation") : element,
    },
    {
      path: "/einstellungen",
      element: isProfileComplete
        ? getElement("/einstellungen", <Settings />, "die Organisationseinstellungen")
        : element,
    },
    {
      path: "/nutzer/neu",
      element: isProfileComplete ? getElement("/nutzer/neu", <UserEditor />, "die Benutzer") : element,
    },
    {
      path: "/nutzer/bearbeiten/:id",
      element: isProfileComplete ? getElement("/nutzer/bearbeiten/:id", <UserEditor />, "die Benutzer") : element,
    },
    { path: "/hilfe", element: isProfileComplete ? <Help /> : element },
    { path: "/organization/neu", element: <Organization /> },
    { path: "/invite/:uuid", element: <Invitation /> },
    { path: "/benutzer", element: isProfileComplete ? <UserSettings /> : element },
    { path: "/reset", element: <ResetPassword /> },
    {
      path: "/super-admin",
      element: isProfileComplete ? getElement("/super-admin", <SuperAdmin />, "das Admin dashboard") : element,
    },
    { path: "/*", element: <PageNotFound /> },
  ]);

  return routes;
};

export default Routes;
