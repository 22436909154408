import UserSettingsIcon from "../../ui/icons/UserSettingsIcon";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Icon, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../hooks/use-user";
import { ISidebarItem } from "../../interface/interface";
import GearIcon from "../../ui/icons/GearIcon";
import HelpIcon from "../../ui/icons/HelpIcon";
import HomeIcon from "../../ui/icons/HomeIcon";
import LocationIcon from "../../ui/icons/LocationIcon";
import LogoutIcon from "../../ui/icons/LogoutIcon";
import PersonAddIcon from "../../ui/icons/PersonAddIcon";
import SearchIcon from "../../ui/icons/SearchIcon";
import SendIcon from "../../ui/icons/SendIcon";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";

const Sidebar = () => {
  const { user } = useUser();

  let sidebarItems: ISidebarItem[] = [];

  if (
    user?.permissionOrganizationAdmin ||
    ((user?.permittedAnyCompanyLocations || user?.permittedContentWithoutCompanyLocationAssignment) &&
      user?.permissionApplicants !== "NONE")
  ) {
    sidebarItems.push(
      {
        title: "Bewerbercockpit",
        icon: <HomeIcon />,
        link: "/",
      },
      {
        title: "Bewerber",
        icon: <PersonAddIcon />,
        link: "/bewerber",
      }
    );
  }

  if (
    user?.permissionOrganizationAdmin ||
    (user?.permittedAnyCompanyLocations && user?.permissionJobOffers !== "NONE")
  ) {
    sidebarItems.push({
      title: "Stellenangebote",
      icon: <SearchIcon />,
      link: "/stellenangebote",
    });
  }

  if (
    user?.permissionOrganizationAdmin ||
    (user?.permissionCompanyLocations !== "NONE" && user?.permittedAnyCompanyLocations)
  ) {
    sidebarItems.push({
      title: "Standorte",
      icon: <LocationIcon />,
      link: "/standorte",
    });
  }

  if (
    user?.allowedFunctionMailAutomation &&
    (user?.permissionMailTemplates !== "NONE" || user?.permissionOrganizationAdmin)
  ) {
    sidebarItems.push({
      title: "Email-Vorlagen",
      icon: <SendIcon />,
      link: "/email-vorlagen",
    });
  }

  if (user?.permissionOrganizationAdmin) {
    sidebarItems.push({
      title: "Einstellungen",
      icon: <GearIcon />,
      link: "/einstellungen",
    });
  }

  sidebarItems.push(
    {
      title: "Benutzereinstellungen",
      icon: <UserSettingsIcon />,
      link: "/benutzer",
    },
    {
      title: "Hilfe",
      icon: <HelpIcon />,
      link: "/hilfe",
      end: true,
    }
  );

  if (user?.showSuperadminPanel) {
    sidebarItems = [
      {
        title: "Superadmin",
        icon: <GearIcon />,
        link: "/super-admin",
      },
    ];
  }

  const location = useLocation();

  useEffect(() => {
    // rerender on location change
  }, [location]);

  return (
    <Box
      color="white"
      padding="1em .75em"
      position="fixed"
      overflowX="hidden"
      top="0"
      left="0"
      bottom="0"
      height="100%"
      zIndex="10"
      className="group w-[82px] hover:w-[270px] bg-gradient-to-tr from-sky-400 to-indigo-800"
      transition="0.2s ease-in-out all"
    >
      <Box className="flex flex-col gap-12 h-full">
        <Logo />
        <Box className="flex flex-col gap-4 h-full">
          {sidebarItems.map((item, index) => (
            <Box key={index} width="100%" className={item.end ? "relative grow" : ""}>
              <SidebarItem
                {...item}
                isActive={
                  (item.link === "/" && location.pathname === item.link) ||
                  (item.link !== "/" && location.pathname.includes(item.link))
                }
              />
            </Box>
          ))}
          {false && <TestLoginItem />}
          <ProfileSidebarItem isActive={location.pathname === "/profile"} />
        </Box>
      </Box>
    </Box>
  );
};

const TestLoginItem = () => {
  const { loginWithRedirect } = useAuth0();

  const login = () => {
    loginWithRedirect({
      appState: {
        returnTo: "/",
      },
    });
  };

  return (
    <Link to="#" onClick={login} className="mt-auto">
      <Box
        _hover={{ background: "rgba(249,250,252, 0.2)" }}
        fill={"white"}
        color={"white"}
        padding="0.5em .875em"
        borderRadius="8px"
      >
        <Box display="flex" gap="1em" className="group items-center">
          <Box height="1.75em" width="1.75em">
            <Icon />
          </Box>
          <Box width="100%" className="hidden opacity-0 group-hover:opacity-100 group-hover:flex transition-all">
            <Text className="whitespace-nowrap">Test Login</Text>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

const ProfileSidebarItem = ({ isActive }: { isActive: boolean }) => {
  const [hover, setHover] = useState(false);
  const { logout } = useAuth0();
  const { user } = useUser();

  return (
    <Link
      to="#"
      className="mt-auto"
      onClick={() =>
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
      }
    >
      <Box
        _hover={{ background: "rgba(249,250,252, 0.2)" }}
        padding="0.5em"
        borderRadius="8px"
        background={isActive ? "rgba(249,250,252, 0.2)" : ""}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box className="flex group items-center px-1 overflow-hidden">
          <Image src={user?.organizationImage} className="h-8 mr-3" />
          <Box className="hidden text-white opacity-0 justify-between group-hover:flex group-hover:opacity-100 items-center w-full transition-all overflow-hidden">
            <Text className="whitespace-nowrap text-ellipsis overflow-hidden">{user?.organizationName}</Text>
            <div className={hover ? "text-red-400" : ""}>
              <LogoutIcon />
            </div>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default Sidebar;
