import React from "react";
import { Home as HomeComponent } from "../components/home/Home";

const Home = () => {
  return (
    <div>
      <HomeComponent />
    </div>
  );
};

export default Home;
