import React from "react";

const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px">
      <path
        fill="currentColor"
        d="M120-120v-720h360v80H200v560h280v80H120Zm520-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"
      />
    </svg>
  );
};

export default LogoutIcon;
