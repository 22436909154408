import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ISidebarItem } from "../../interface/interface";

const SidebarItem = ({ title, icon, link, isActive, end }: ISidebarItem) => {
  return (
    <Link to={link} className={end ? "absolute bottom-0 w-full" : ""}>
      <Box
        _hover={{ background: "rgba(249,250,252, 0.2)" }}
        background={isActive ? "rgba(249,250,252, 0.2)" : ""}
        fill={isActive ? "yellow.100" : "white"}
        color={isActive ? "yellow.100" : "white"}
        padding="0.5em .875em"
        borderRadius="8px"
      >
        <Box display="flex" gap="1em" className="group items-center">
          <Box height="1.75em" width="1.75em">
            {icon}
          </Box>
          <Box width="100%" className="hidden opacity-0 group-hover:opacity-100 group-hover:flex transition-all">
            <Text className="whitespace-nowrap">{title}</Text>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default SidebarItem;
